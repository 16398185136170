//Сервис для филала
import httpClient from "@/services/http.service"

const universityRepresentationService = {
    post(form) {
        return httpClient.post(`university/representation/create`, form)
    },
    put(form) {
        return httpClient.put(`university/representation/update/${form.id}`, form)
    },
    delete(university_representation_id) {
        return httpClient.delete(`university/representation/delete/${university_representation_id}`)
    },
    get() {
        return httpClient.get(`representation`)
    },
    getRepresentationTypes() {
        return httpClient.get(`representation-type`)
    },
    getRepresentationAgreementTypes() {
        return httpClient.get(`representation-agreement-type`)
    },
    getBranchTypes() {
        return httpClient.get(`branch-type`)
    },
    getRepresentationById(university_representation_id) {
        return httpClient.get(`representation/show/${university_representation_id}`)
    },
    getAllRepresentation(university_id, size, page) {
        return httpClient.get(`representation/all?university_id=${university_id}&size=${size}&page=${page}`)
    },
}

export default universityRepresentationService