//Хранилище данных для зарубежных университетов

import foreignUniversityService from "@/services/university/foreignUniversity.service";

const foreignUniversity = {
  namespaced: true,
  actions: {
    async DELETE_FOREIGN_UNIVERSITY({commit}, foreign_university_id) {
      try {
        const data = await foreignUniversityService.delete(foreign_university_id)
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_FOREIGN_UNIVERSITY({commit, state}, form) {
      try {
        await foreignUniversityService.put(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_FOREIGN_UNIVERSITY({commit, state}, form) {

      try {
        await foreignUniversityService.post(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_FOREIGN_UNIVERSITIES({commit}) {
      try {
        const {status, data} = await foreignUniversityService.get()
        if (status === 200) {
          commit('SET_FOREIGN_UNIVERSITIES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_COUNTRIES({commit}) {
      try {
        const {status, data} = await foreignUniversityService.getCountries()
        if (status === 200) {
          commit('SET_COUNTRIES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_CITIES({commit}) {
      try {
        const {status, data} = await foreignUniversityService.getCities()
        if (status === 200) {
          commit('SET_CITIES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
  },
  mutations: {
    SET_FOREIGN_UNIVERSITIES(state, data) {
      state.foreignUniversity_form.foreignUniversities = data
    },
    SET_COUNTRIES(state, data) {
      state.foreignUniversity_form.foreignCountries = data
    },
    SET_CITIES(state, data) {
      state.foreignUniversity_form.foreignCities = data
    },
  },
  state: {
    foreignUniversity_form: {
      foreignUniversities: [],
      foreignCountries: [],
      foreignCities: [],
    }
  },
  getters: {}
}

export default foreignUniversity
