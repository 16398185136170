
import specialNeedConditionService from "@/services/university/specialNeedCondition.service"

const specialNeedCondition = {
    namespaced: true,
    actions: {

        async DELETE_SPECIAL_NEED_CONDITION({commit}, special_need_condition_id) {
            try {
                const data = await specialNeedConditionService.delete(special_need_condition_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_SPECIAL_NEED_CONDITION({commit, state}, form) {

            try {
                await specialNeedConditionService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_SPECIAL_NEED_CONDITION({commit, state}, form) {

            try {
                await specialNeedConditionService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SPECIAL_NEED_CONDITIONS({commit}) {
            try {
                const {status, data} = await specialNeedConditionService.get()
                if (status === 200) {
                    console.log('GET_SPECIAL_NEED_CONDITIONS', data)
                    commit('SET_SPECIAL_NEED_CONDITIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_SPECIAL_NEED_CONDITIONS(state, data) {
            state.specialNeedCondition_form.specialNeedConditions = data
        },
    },
    state: {
        specialNeedCondition_form: {

            specialNeedConditions: []
        }
    },
    getters: {}
}

export default specialNeedCondition