import teacherCommonService from "@/services/common/teacherCommon.service";

const teacherCommon = {
    namespaced: true,
    actions: {
        async GET_TEACHER_TRAINING_DIRECTION({commit}) {
            try {
                const {status, data} = await teacherCommonService.getTeacherTrainingDirection()
                if (status === 200) {
                    commit('SET_TEACHER_TRAINING_DIRECTION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
    },
    mutations: {
        SET_TEACHER_TRAINING_DIRECTION(state, data) {
            state.teacherCommon_form.teacherTrainingDirection = data
        },
    },
    state: {
        teacherCommon_form: {
            teacherTrainingDirection: [],
        }

    },
    getters: {}
}

export default teacherCommon