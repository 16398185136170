import httpClient from "@/services/http.service"

const additionalForeignTeacherService = {
    getForeignTeacherById(foreign_teacher_id) {
        return httpClient.get(`foreign-teacher/show/${foreign_teacher_id}`)
    },
    getFinancingSource() {
        return httpClient.get(`foreign-teacher/financing-source`)
    },
    getForeignTeacherFormat() {
        return httpClient.get(`foreign-teacher/format`)
    },
    post(form){
        return httpClient.post(`foreign-teacher/create`, form)
    },
    put(form){
        return httpClient.put(`foreign-teacher/update/${form.id}`, form)
    },
}

export default additionalForeignTeacherService