//Хранилище данных справочников
import studentDataService from "@/services/student/studentData.service";
import buildingService from "@/services/university/building.service";

const studentData = {
    namespaced: true,
    actions: {
        async GET_STUDENTS({commit}, filterObject) {
            try {
                const {
                    status,
                    data
                } = await studentDataService.get(filterObject.page, filterObject.last_name_doc, filterObject.IID, filterObject.enroll_status_ids, filterObject.ep_name,)
                if (status === 200) {
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_STUDENT_BY_ID({commit}, student_id) {
            console.log('GET_STUDENT_BY_ID', student_id)
            try {
                const {
                    status,
                    data
                } = await studentDataService.getStudentById(student_id)
                if (status === 200) {
                    commit('SET_STUDENT_BY_ID', data)
                    console.log(data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async POST_STUDENT_DATA({commit}, sendDataObject) {
            try {
                await studentDataService.post(sendDataObject)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_STUDENT_DATA({commit}, sendDataObject) {
            try {
                await studentDataService.put(sendDataObject)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_STUDENT_DATA({commit}, student_id) {
            try {
                const data = await studentDataService.delete(student_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_STUDENT_SPORT_CATEGORY({commit}, id) {
            try {
                const {status, data} = await studentDataService.deleteStudentSportCategory(id);
                if (status === 200) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_STUDENT_STUDY_LANGUAGE({commit}, id) {
            try {
                const {status, data} = await studentDataService.deleteStudentStudyLanguage(id);
                if (status === 200) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_STUDENTS_ADMIN({commit}, filterObject) {
            try {
                const {
                    status,
                    data
                } = await studentDataService.getStudentsAdmin(filterObject.university_id, filterObject.page,
                    filterObject.last_name_doc, filterObject.IID, filterObject.enroll_status_ids, filterObject.ep_name)
                if (status === 200) {
                    commit('SET_STUDENTS_ADMIN', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
    },
    mutations: {
        SET_STUDENTS(state, data) {
            state.studentData_form.students = data
        },
        SET_STUDENTS_ADMIN(state, data) {
            state.studentData_form.adminStudents = data
        },
        SET_STUDENT_BY_ID(state, data) {
            state.studentData_form.studentById = data
        },
    },
    state: {

        studentData_form: {
            students: [],
            adminStudents: [],
            studentById: [],
        }

    },
    getters: {}
}

export default studentData