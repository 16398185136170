//Сервис для специальных потребностей
import httpClient from "@/services/http.service"

const specialNeedConditionService = {
    post(form) {
        return httpClient.post(`special-needs-condition/create`, form)
    },
    put(form) {
        return httpClient.put(`special-needs-condition/update/${form.id}`, form)
    },
    delete(special_need_condition_id) {
        return httpClient.delete(`special-needs-condition/delete/${special_need_condition_id}`)
    },
    get() {
        return httpClient.get(`special-needs-condition/get_by_university_id`)
    },
}

export default specialNeedConditionService