//Хранилище данных для дисциплин
import mobilityService from "@/services/student/mobility.service"

const mobility = {
    namespaced: true,
    actions: {

        async DELETE_MOBILITY({commit}, mobility_id) {
            try {
                const data = await mobilityService.delete(mobility_id);
                if (data) {
                    console.log(data, 'mobility deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_MOBILITY({commit, state}, form) {

            try {
                await mobilityService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_MOBILITY({commit, state}, form) {

            try {
                await mobilityService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_MOBILITIES({commit}) {
            try {
                const {status, data} = await mobilityService.get()
                if (status === 200) {
                    console.log('GET_MOBILITIES', data)
                    commit('SET_MOBILITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_MOBILITY_TYPES({commit}) {
            try {
                const {status, data} = await mobilityService.getMobilityTypes()
                if (status === 200) {
                    console.log('GET_MOBILITY_TYPES', data)
                    commit('SET_MOBILITY_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_COUNTRIES({commit}) {
            try {
                const {status, data} = await mobilityService.getCountries()
                if (status === 200) {
                    console.log('GET_COUNTRIES', data)
                    commit('SET_COUNTRIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_UNIVERSITIES({commit}) {
            try {
                const {status, data} = await mobilityService.getUniversities()
                if (status === 200) {
                    console.log('GET_UNIVERSITIES', data)
                    commit('SET_UNIVERSITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_FOREIGN_UNIVERSITIES({commit}) {
            try {
                const {status, data} = await mobilityService.getForeignUniversities()
                if (status === 200) {
                    console.log('GET_FOREIGN_UNIVERSITIES', data)
                    commit('SET_FOREIGN_UNIVERSITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_PAYMENT_TYPES({commit}) {
            try {
                const {status, data} = await mobilityService.getPaymentTypes()
                if (status === 200) {
                    console.log('GET_PAYMENT_TYPES', data)
                    commit('SET_PAYMENT_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_AGREEMENT_TYPES({commit}) {
            try {
                const {status, data} = await mobilityService.getAgreementTypes()
                if (status === 200) {
                    console.log('GET_AGREEMENT_TYPES', data)
                    commit('SET_AGREEMENT_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_MOBILITY_STUDY_FORMS({commit}) {
            try {
                const {status, data} = await mobilityService.getMobilityStudyForms()
                if (status === 200) {
                    console.log('GET_MOBILITY_STUDY_FORMS', data)
                    commit('SET_MOBILITY_STUDY_FORMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_STUDENT_BY_NAME_AND_IID({commit},search_field) {
            try {
                const {status, data} = await mobilityService.getStudentByNameAndIid(search_field);
                if (status === 200) {
                    console.log('SET_STUDENT_BY_NAME_AND_IID', data)
                    commit('SET_STUDENT_BY_NAME_AND_IID', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_FOREIGN_UNIVERSITIES_BY_ID({commit},university_id) {
            try {
                const {status, data} = await mobilityService.getForeignUniversitiesById(university_id);
                if (status === 200) {
                    console.log('SET_FOREIGN_UNIVERSITIES_BY_ID', data)
                    commit('SET_FOREIGN_UNIVERSITIES_BY_ID', data)
                    return data;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_UNIVERSITIES_BY_ID({commit},university_id) {
            try {
                const {status, data} = await mobilityService.getUniversitiesById(university_id);
                if (status === 200) {
                    console.log('SET_UNIVERSITIES_BY_ID', data)
                    commit('SET_UNIVERSITIES_BY_ID', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
    },
    mutations: {

        SET_MOBILITIES(state, data) {
            state.mobility_form.mobilities = data
        },
        SET_MOBILITY_TYPES(state, data) {
            state.mobility_form.mobilityTypes = data
        },
        SET_COUNTRIES(state, data) {
            state.mobility_form.countries = data
        },
        SET_UNIVERSITIES(state, data) {
            state.mobility_form.universities = data
        },
        SET_FOREIGN_UNIVERSITIES(state, data) {
            state.mobility_form.foreignUniversities = data
        },
        SET_PAYMENT_TYPES(state, data) {
            state.mobility_form.paymentTypes = data
        },
        SET_AGREEMENT_TYPES(state, data) {
            state.mobility_form.agreementTypes = data
        },
        SET_MOBILITY_STUDY_FORMS(state, data) {
            state.mobility_form.mobilityStudyForms = data
        },
        SET_STUDENT_BY_NAME_AND_IID(state, data) {
            state.mobility_form.searchedStudents = data
        },
        SET_FOREIGN_UNIVERSITIES_BY_ID(state, data) {
            state.mobility_form.universitiesById = data
        },
        SET_UNIVERSITIES_BY_ID(state, data) {
            state.mobility_form.universitiesById = data
        },
    },
    state: {
        mobility_form: {
            mobilities: [],
            mobilityTypes: [],
            countries: [],
            universities: [],
            foreignUniversities: [],
            paymentTypes: [],
            agreementTypes: [],
            mobilityStudyForms: [],
            searchedStudents: [],
            universitiesById: []
        }
    },
    getters: {}
}

export default mobility