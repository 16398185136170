
import departmentService from "@/services/university/department.service"

const department = {
    namespaced: true,
    actions: {

        async DELETE_DEPARTMENT({commit}, department_id) {
            try {
                const data = await departmentService.delete(department_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_DEPARTMENT({commit, state}, form) {

            try {
                await departmentService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_DEPARTMENT({commit, state}, form) {

            try {
                await departmentService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DEPARTMENTS({commit}) {
            try {
                const {status, data} = await departmentService.get()
                if (status === 200) {
                    console.log('GET_DEPARTMENTS', data)
                    commit('SET_DEPARTMENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_DEPARTMENTS(state, data) {
            state.department_form.departments = data
        },
    },
    state: {
        department_form: {
            departments: []
        }
    },
    getters: {}
}

export default department