import teacherPublicationService from "@/services/teacher/teacherPublication.service";
import teacherDataService from "@/services/teacher/teacherData.service";

const teacherPublication = {
    namespaced: true,
    actions: {

        async DELETE_TEACHER_PUBLICATION({commit}, publication_id) {
            try {
                const data = await teacherPublicationService.delete(publication_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_TEACHER_PUBLICATION({commit, state}, form) {

            try {
                await teacherPublicationService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_TEACHER_PUBLICATION({commit, state}, form) {

            try {
                await teacherPublicationService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TEACHER_PUBLICATION({commit}, filterObject) {
            try {
                const {status, data} = await teacherPublicationService.get(filterObject.page)
                if (status === 200) {
                    console.log('GET_TEACHER_PUBLICATION', data)
                    commit('SET_TEACHER_PUBLICATION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_SEARCH_TEACHER_BY_NAME({commit}, name_doc) {
            try {
                const {
                    status,
                    data
                } = await teacherPublicationService.getSearchTeacherByName(name_doc)
                if (status === 200) {
                    console.log('GET_SEARCH_TEACHER_BY_NAME', data)
                    commit('SET_SEARCH_TEACHER_BY_NAME', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_SEARCH_PUBLICATION_BY_NAME({commit}, name) {
            try {
                const {
                    status,
                    data
                } = await teacherPublicationService.getSearchPublicationByName(name)
                if (status === 200) {
                    console.log('GET_SEARCH_PUBLICATION_BY_NAME', data)
                    commit('SET_SEARCH_PUBLICATION_BY_NAME', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_PUBLICATION_BY_ID({commit}, id) {
            try {
                const {
                    status,
                    data
                } = await teacherPublicationService.getPublicationById(id)
                if (status === 200) {
                    console.log('GET_PUBLICATION_BY_ID', data)
                    commit('SET_PUBLICATION_BY_ID', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_TEACHER_PUBLICATION(state, data) {
            state.teacherPublication_form.teacherPublication = data
        },
        SET_SEARCH_TEACHER_BY_NAME(state, data) {
            state.teacherPublication_form.teacherByName = data
        },
        SET_SEARCH_PUBLICATION_BY_NAME(state, data) {
            state.teacherPublication_form.publicationByName = data
        },
        SET_PUBLICATION_BY_ID(state, data) {
            state.teacherPublication_form.publicationById = data
        },
    },
    state: {
        teacherPublication_form: {
            teacherPublication: [],
            teacherByName: [],
            publicationByName: [],
            publicationById: {},
        }
    },
    getters: {}
}

export default teacherPublication