import httpClient from "@/services/http.service"

const internationalContractsService = {
    get() {
        return httpClient.get(`international-contract`)
    },
    getAll(university_id, size, page) {
        return httpClient.get(`international-contract/all?university_id=${university_id}&size=${size}&page=${page}`)
    },
    post(sendDataObject){
        return httpClient.post(`international-contract/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`international-contract/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(international_contracts_id) {
        return httpClient.delete(`international-contract/delete/${international_contracts_id}`)
    },
}

export default internationalContractsService