
import foreignStudentService from "@/services/archive/foreignStudent.service"

const foreignStudent = {
    namespaced: true,
    actions: {

        async DELETE_FOREIGN_STUDENT({commit}, foreign_student_id) {
            try {
                const data = await foreignStudentService.delete(foreign_student_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_FOREIGN_STUDENT({commit, state}, form) {

            try {
                await foreignStudentService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_FOREIGN_STUDENT({commit, state}, form) {

            try {
                await foreignStudentService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_FOREIGN_STUDENTS({commit}) {
            try {
                const {status, data} = await foreignStudentService.get()
                if (status === 200) {
                    console.log('GET_FOREIGN_STUDENTS', data)
                    commit('SET_FOREIGN_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_FOREIGN_STUDENTS(state, data) {
            state.foreignStudent_form.foreignStudents = data
        },
    },
    state: {
        foreignStudent_form: {

            foreignStudents: []
        }
    },
    getters: {}
}

export default foreignStudent