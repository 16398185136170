
import trilingualEducationService from "@/services/archive/trilingualEducation.service"

const trilingualEducation = {
    namespaced: true,
    actions: {

        async DELETE_TRILINGUAL_EDUCATION({commit}, trilingual_education_id) {
            try {
                const data = await trilingualEducationService.delete(trilingual_education_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_TRILINGUAL_EDUCATION({commit, state}, form) {

            try {
                await trilingualEducationService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_TRILINGUAL_EDUCATION({commit, state}, form) {

            try {
                await trilingualEducationService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TRILINGUAL_EDUCATIONS({commit}) {
            try {
                const {status, data} = await trilingualEducationService.get()
                if (status === 200) {
                    console.log('GET_TRILINGUAL_EDUCATIONS', data)
                    commit('SET_TRILINGUAL_EDUCATIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_TRILINGUAL_EDUCATIONS(state, data) {
            state.trilingualEducation_form.trilingualEducations = data
        },
    },
    state: {
        trilingualEducation_form: {

            trilingualEducations: []
        }
    },
    getters: {}
}

export default trilingualEducation