import publicationService from "@/services/university/publication.service";

const publication = {
    namespaced: true,
    actions: {

        async DELETE_PUBLICATION({commit}, department_id) {
            try {
                const data = await publicationService.delete(department_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_PUBLICATION({commit, state}, form) {

            try {
                await publicationService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_PUBLICATION({commit, state}, form) {

            try {
                await publicationService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_PUBLICATION({commit}) {
            try {
                const {status, data} = await publicationService.get()
                if (status === 200) {
                    console.log('GET_PUBLICATION', data)
                    commit('SET_PUBLICATION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_SIENCE_FIELD({commit}) {
            try {
                const {status, data} = await publicationService.getScienceField()
                if (status === 200) {
                    console.log('GET_SIENCE_FIELD', data)
                    commit('SET_SIENCE_FIELD', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ALL_PUBLICATION({commit}, {university_id, size, page}) {
            try {
                const {status, data} = await publicationService.getAll(university_id, size, page)
                if (status === 200) {
                    console.log('SET_ALL_PUBLICATION', data)
                    commit('SET_ALL_PUBLICATION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

    },
    mutations: {
        SET_PUBLICATION(state, data) {
            state.publication_form.publications = data
        },
        SET_ALL_PUBLICATION(state, data) {
            state.publication_form.allPublications = data
        },
        SET_SIENCE_FIELD(state, data) {
            state.publication_form.sienceField = data
        },
    },
    state: {
        publication_form: {
            publications: [],
            allPublications: {},
            sienceField:[]
        }
    },
    getters: {}
}

export default publication