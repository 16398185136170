//Сервис для органа акредитации
import httpClient from "@/services/http.service"

const accreditationService = {
    post(form) {
        return httpClient.post(`accreditation/create`, form)
    },
    put(form) {
        return httpClient.put(`accreditation/update/${form.id}`, form)
    },
    delete(accreditation_id) {
        return httpClient.delete(`accreditation/delete/${accreditation_id}`)
    },
    get() {
        return httpClient.get(`accreditation/get_by_university_id`)
    },
    getAccreditationTypes() {
        return httpClient.get(`accreditation/type`)
    },
}

export default accreditationService