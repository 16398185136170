//Сервис для дисциплин
import httpClient from "@/services/http.service"

const commonService = {
    getAll() {
        return httpClient.get(`common/info`)
    },
    getCitizenships() {
        return httpClient.get(`common/citizenship`)
    },
    getEnglishLevels() {
        return httpClient.get(`common/english-level`)
    },
    getGenders() {
        return httpClient.get(`common/gender`)
    },
    getLanguages() {
        return httpClient.get(`common/language`)
    },
    getCountries() {
        return httpClient.get(`common/country`)
    },
    getStudyForms() {
        return httpClient.get(`common/study-form`)
    },
}

export default commonService