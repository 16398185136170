import futureProfessionsService from "@/services/admin/futureProfessions.service";

const futureProfessions = {
    namespaced: true,
    actions: {

        async DELETE_FUTURE_PROFESSIONS({commit}, futureProfessions_id) {
            try {
                const data = await futureProfessionsService.delete(futureProfessions_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_FUTURE_PROFESSIONS({commit, state}, form) {

            try {
                await futureProfessionsService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_FUTURE_PROFESSIONS({commit, state}, form) {

            try {
                await futureProfessionsService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_FUTURE_PROFESSIONS({commit}) {
            try {
                const {status, data} = await futureProfessionsService.get()
                if (status === 200) {
                    console.log('GET_FUTURE_PROFESSIONS', data)
                    commit('SET_FUTURE_PROFESSIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_FUTURE_PROFESSIONS(state, data) {
            state.futureProfessions_form.futureProfessions = data
        },
    },
    state: {
        futureProfessions_form: {

            futureProfessions: []
        }
    },
    getters: {}
}


export default futureProfessions