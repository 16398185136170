//Сервис для кафедр
import httpClient from "@/services/http.service"

const departmentService = {
    post(form) {
        return httpClient.post(`department/create`, form)
    },
    put(form) {
        return httpClient.put(`department/update/${form.id}`, form)
    },
    delete(department_id) {
        return httpClient.delete(`department/delete/${department_id}`)
    },
    get() {
        return httpClient.get(`department`)
    },
}

export default departmentService