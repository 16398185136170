//Сервис для ОП
import httpClient from "@/services/http.service"

const educationProgramService = {
    getEducationFields() {
        return httpClient.get(`education-field`)
    },
    getEducationDirections() {
        return httpClient.get(`education-direction`)
    },
    getEducationGroups() {
        return httpClient.get(`education-group`)
    },
    getEducationProgramInfo(education_program_id) {
        return httpClient.get(`education-program/get-education-program-info?id=${education_program_id}`)
    },
    getEducationProgramById(education_program_id) {
        return httpClient.get(`education-program/get-education-program-by-id/${education_program_id}`)
    },
    post(form) {
        return httpClient.post(`education-program/create`, form)
    },
    put(form) {
        return httpClient.put(`education-program/update/${form.id}`, form)
    },
    delete(education_program_id) {
        return httpClient.delete(`education-program/delete/${education_program_id}`)
    },
    get() {
        return httpClient.get(`education-program`)
    },
    getApplicationEducationPrograms() {
        return httpClient.get(`education-program/application`)
    },
    getEducationProgramByCodeOrName(ep) {
        return httpClient.get(`education-program/get-education-program-by-name-or-code?ep=${ep}`)
    },
    getEducationProgramUniversities(searching_university_name, is_foreign) {
        return httpClient.get(`university/search?searching_university_name=${searching_university_name}&is_foreign=${is_foreign}`)
    },
    getEducationProgramUniversityById(university_id, is_foreign) {
        return httpClient.get(`university/get-national-foreign-university-by-id?university_id=${university_id}&is_foreign=${is_foreign}`)
    },
}

export default educationProgramService