//Сервис для расход
import httpClient from "@/services/http.service"

const universityExpenseService = {
    post(form) {
        return httpClient.post(`university/expenses/create`, form)
    },
    put(form) {
        return httpClient.put(`university/expenses/update/${form.id}`, form)
    },
    delete(university_expense_id) {
        return httpClient.delete(`university/expenses/delete/${university_expense_id}`)
    },
    get() {
        return httpClient.get(`university/expenses`)
    },
    getExpenseTypes() {
        return httpClient.get(`university/expenses/type`)
    },
    getExpenseFinancingSources() {
        return httpClient.get(`university/expenses/financing-source`)
    },
}

export default universityExpenseService;