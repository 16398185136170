//Сервис для университета
import httpClient from "@/services/http.service"

const researchWorkService = {
    post(form) {
        return httpClient.post(`research-work/create`, form)
    },
    put(form) {
        return httpClient.put(`research-work/update/${form.id}`, form)
    },
    delete(discipline_id) {
        return httpClient.delete(`research-work/delete/${discipline_id}`)
    },
    get(page = 1) {
        return httpClient.get(`research-work?page=` + page)
    },
    getResearchWorkTypes() {
        return httpClient.get(`research-work/type`)
    },
}

export default researchWorkService
