import socialMediaPostsService from "@/services/university/socialMediaPosts.service";

const socialMediaPosts = {
    namespaced: true,
    actions: {

        async DELETE_SOCIAL_MEDIA_POSTS({commit}, post_id) {
            try {
                const data = await socialMediaPostsService.delete(post_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_SOCIAL_MEDIA_POSTS({commit, state}, form) {

            try {
                await socialMediaPostsService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_SOCIAL_MEDIA_POSTS({commit, state}, form) {

            try {
                await socialMediaPostsService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SOCIAL_MEDIA_POSTS({commit}) {
            try {
                const {status, data} = await socialMediaPostsService.get()
                if (status === 200) {
                    console.log('GET_SOCIAL_MEDIA_POSTS', data)
                    commit('SET_SOCIAL_MEDIA_POSTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },



    },
    mutations: {
        SET_SOCIAL_MEDIA_POSTS(state, data) {
            state.socialMediaPosts_form.socialMediaPosts = data
        },

    },
    state: {
        socialMediaPosts_form: {
            socialMediaPosts: [],
            scientific:[]
        }
    },
    getters: {}
}

export default socialMediaPosts