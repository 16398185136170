//Сервис для общежития
import httpClient from "@/services/http.service"

const dormitoryService = {
    post(form) {
        return httpClient.post(`dormitory/create`, form)
    },
    put(form) {
        return httpClient.put(`dormitory/update/${form.id}`, form)
    },
    delete(dormitory_id) {
        return httpClient.delete(`dormitory/delete/${dormitory_id}`)
    },
    get() {
        return httpClient.get(`dormitory/get_by_university_id`)
    },
    getDormitoryTypes() {
        return httpClient.get(`dormitory-type`)
    },
}

export default dormitoryService