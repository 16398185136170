//Сервис для международных проектов
import httpClient from "@/services/http.service"

const internationalProjectService = {
    post(form) {
        return httpClient.post(`international-project/create`, form)
    },
    put(form) {
        return httpClient.put(`international-project/update/${form.id}`, form)
    },
    delete(international_project_id) {
        return httpClient.delete(`international-project/delete/${international_project_id}`)
    },
    get() {
        return httpClient.get(`international-project`)
    },
    getAll(university_id, size, page) {
        return httpClient.get(`international-project/all?university_id=${university_id}&size=${size}&page=${page}`)
    },
    getInternationalProjectFinancing() {
        return httpClient.get(`international-project/financing`)
    },
    getInternationalProjectType() {
        return httpClient.get(`international-project/type`)
    },
}

export default internationalProjectService