import httpClient from "@/services/http.service"

const socialMediaPostsService = {
    get() {
        return httpClient.get(`social-media-posts`)
    },
    post(sendDataObject){
        return httpClient.post(`social-media-posts/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`social-media-posts/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(posts_id) {
        return httpClient.delete(`social-media-posts/delete/${posts_id}`)
    },
}

export default socialMediaPostsService