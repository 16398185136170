import universityFacilitiesService from "@/services/university/universityFacilities.service"

const universityFacilities = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_FACILITIES({commit}, id) {
            try {
                const data = await universityFacilitiesService.delete(id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_UNIVERSITY_FACILITIES({commit, state}, form) {
            try {
                await universityFacilitiesService.put(form)
                return {result: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return false
            }
        },

        async POST_UNIVERSITY_FACILITIES({commit, state}, form) {
            try {
                await universityFacilitiesService.post(form)
                return {result: true}
            } catch (e) {
                if (e.response.status === 409){
                    return {message: e.response.message, result: false}
                } else {
                    commit('SET_ERROR', e, {root: true})
                }
            }
            return false
        },

        async GET_UNIVERSITY_FACILITIES({commit}) {
            try {
                const {status, data} = await universityFacilitiesService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_FACILITIES', data)
                    commit('SET_UNIVERSITY_FACILITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_UNIVERSITY_FACILITIES(state, data) {
            state.universityFacilities_form.universityFacilitiess = data
        },
    },
    state: {
        universityFacilities_form: {
            universityFacilities: [],
        }
    },
    getters: {}
}

export default universityFacilities