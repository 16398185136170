import additionalForeignTeacherService from "@/services/teacher/additionalForeignTeacher.service";

const additionalForeignTeacher = {
    namespaced: true,
    actions: {
        async GET_FOREIGN_TEACHER_BY_ID({commit}, foreign_teacher_id) {
            try {
                const {
                    status,
                    data
                } = await additionalForeignTeacherService.getForeignTeacherById(foreign_teacher_id)
                if (status === 200) {
                    commit('SET_FOREIGN_TEACHER', data)
                    console.log(typeof(data))
                }
            } catch (e) {
                console.log(e)
                return false
                // commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_FINANCING_SOURCE({commit}) {
            try {
                const {
                    status,
                    data
                } = await additionalForeignTeacherService.getFinancingSource()
                if (status === 200) {
                    commit('SET_FINANCING_SOURCE', data)
                }
            } catch (e) {
                console.log(e)
                return false
                // commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_FOREIGN_TEACHER_FORMAT({commit}) {
            try {
                const {
                    status,
                    data
                } = await additionalForeignTeacherService.getForeignTeacherFormat()
                if (status === 200) {
                    commit('SET_FOREIGN_TEACHER_FORMAT', data)
                }
            } catch (e) {
                console.log(e)
                return false
                // commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async POST_FOREIGN_TEACHER_DATA({commit}, sendDataObject) {
            try {
                const {status, data} = await additionalForeignTeacherService.post(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_FOREIGN_TEACHER_DATA({commit}, sendDataObject) {
            try {
                const {status, data} = await additionalForeignTeacherService.put(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }

            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_FOREIGN_TEACHER(state, data) {
            state.foreignTeacher_form.foreignTeacherData = data
        },
        SET_FINANCING_SOURCE(state, data) {
            state.foreignTeacher_form.financingSource = data
        },

        SET_FOREIGN_TEACHER_FORMAT(state, data) {
            state.foreignTeacher_form.foreignTeacherFormat = data
        },
    },
    state: {
        foreignTeacher_form: {
            foreignTeacherData: [],
            financingSource: [],
            foreignTeacherFormat: [],

        }

    },
    getters: {}
}

export default additionalForeignTeacher