
import universityDistanceLearningService from "@/services/university/universityDistanceLearning.service"

const universityDistanceLearning = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_DISTANCE_LEARNING({commit}, distance_learning_id) {
            try {
                const data = await universityDistanceLearningService.delete(distance_learning_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_UNIVERSITY_DISTANCE_LEARNING({commit, state}, form) {

            try {
                await universityDistanceLearningService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_UNIVERSITY_DISTANCE_LEARNING({commit, state}, form) {

            try {
                await universityDistanceLearningService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_UNIVERSITY_DISTANCE_LEARNINGS({commit}) {
            try {
                const {status, data} = await universityDistanceLearningService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_UNIVERSITY_DISTANCE_LEARNINGS', data)
                    commit('SET_UNIVERSITY_UNIVERSITY_DISTANCE_LEARNINGS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_UNIVERSITY_UNIVERSITY_DISTANCE_LEARNINGS(state, data) {
            state.universityDistanceLearning_form.universityDistanceLearnings = data
        },
    },
    state: {
        universityDistanceLearning_form: {

            universityDistanceLearnings: []
        }
    },
    getters: {}
}

export default universityDistanceLearning