//Сервис для зарубежных университетов
import httpClient from "@/services/http.service"

const foreignUniversityService = {
  post(form) {
    return httpClient.post(`foreign-university/create`, form)
  },
  put(form) {
    return httpClient.put(`foreign-university/update/${form.id}`, form)
  },
  delete(discipline_id) {
    return httpClient.delete(`foreign-university/delete/${discipline_id}`)
  },
  get() {
    return httpClient.get(`foreign-university`)
  },
  getCountries() {
    return httpClient.get(`common/country`)
  },
  getCities() {
    return httpClient.get(`common/foreign-city`)
  },
}

export default foreignUniversityService
