//Сервис для филала
import httpClient from "@/services/http.service"

const universitySupportService = {
    post(form) {
        return httpClient.post(`university-support/create`, form)
    },
    put(form) {
        return httpClient.put(`university-support/update/${form.id}`, form)
    },
    delete(id) {
        return httpClient.delete(`university-support/delete/${id}`)
    },
    get() {
        return httpClient.get(`university-support`)
    },
}

export default universitySupportService