
import silverEducationNonformalService from "@/services/archive/silverEducationNonformal.service"

const silverEducationNonformal = {
    namespaced: true,
    actions: {

        async DELETE_SILVER_EDUCATION_NONFORMAL({commit}, silver_education_nonformal_id) {
            try {
                const data = await silverEducationNonformalService.delete(silver_education_nonformal_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_SILVER_EDUCATION_NONFORMAL({commit, state}, form) {

            try {
                await silverEducationNonformalService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_SILVER_EDUCATION_NONFORMAL({commit, state}, form) {

            try {
                await silverEducationNonformalService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SILVER_EDUCATION_NONFORMALS({commit}) {
            try {
                const {status, data} = await silverEducationNonformalService.get()
                if (status === 200) {
                    console.log('GET_SILVER_EDUCATION_NONFORMALS', data)
                    commit('SET_SILVER_EDUCATION_NONFORMALS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_SILVER_EDUCATION_NONFORMALS(state, data) {
            state.silverEducationNonformal_form.silverEducationNonformals = data
        },
    },
    state: {
        silverEducationNonformal_form: {

            silverEducationNonformals: []
        }
    },
    getters: {}
}

export default silverEducationNonformal