//Сервис для зданий
import httpClient from "@/services/http.service"

const buildingService = {
    post(form) {
        return httpClient.post(`building/create`, form)
    },
    put(form) {
        return httpClient.put(`building/update/${form.id}`, form)
    },
    delete(building_id) {
        return httpClient.delete(`building/delete/${building_id}`)
    },
    get() {
        return httpClient.get(`building/get_by_university_id`)
    },
}

export default buildingService