import httpClient from "@/services/http.service"

const internationalActivityService = {
    post(form) {
        return httpClient.post(`international-activity/create`, form)
    },
    put(form) {
        return httpClient.put(`international-activity/update/${form.id}`, form)
    },
    delete(international_activity_id) {
        return httpClient.delete(`international-activity/delete/${international_activity_id}`)
    },
    get() {
        return httpClient.get(`international-activity`)
    },
}

export default internationalActivityService