//Сервис для университета
import httpClient from "@/services/http.service"

const universityService = {
  post(form) {
    return httpClient.post(`university/create`, form)
  },
  put(form) {
    return httpClient.put(`university/update/${form.id}`, form)
  },
  delete(discipline_id) {
    return httpClient.delete(`university/delete/${discipline_id}`)
  },
  get() {
    return httpClient.get(`university`)
  },
  getRegions() {
    return httpClient.get(`region`)
  },
  getScienceFundingTypes() {
    return httpClient.get(`university/science-funding-type`)
  },
  getUniversityTypes() {
    return httpClient.get(`university/type`)
  },
  getUniversity() {
    return httpClient.get(`university/university_by_id`)
  },
}

export default universityService
