
import universitySupportService from "@/services/university/universitySupport.service"

const universitySupport = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_SUPPORT({commit}, id) {
            try {
                const data = await universitySupportService.delete(id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_UNIVERSITY_SUPPORT({commit, state}, form) {

            try {
                await universitySupportService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_UNIVERSITY_SUPPORT({commit, state}, form) {

            try {
                await universitySupportService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_UNIVERSITY_SUPPORT({commit}) {
            try {
                const {status, data} = await universitySupportService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_SUPPORT', data)
                    commit('SET_UNIVERSITY_SUPPORT', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_UNIVERSITY_SUPPORT(state, data) {
            state.universitySupport_form.universitySupports = data
        },
    },
    state: {
        universitySupport_form: {
            universitySupports: [],
        }
    },
    getters: {}
}

export default universitySupport