import httpClient from "@/services/http.service"

const teacherDataService = {
    get(page = 1, name_doc = "", size = "", teacher_type= 0) {
        return httpClient.get(`teacher?page=` + page + `&name_doc=` + name_doc + `&size=` + size + `&teacher_type=` + teacher_type)
    },
    getRelatedData() {
        return httpClient.get(`teacher/related-data`)
    },
    post(sendDataObject){
        return httpClient.post(`teacher/create`, sendDataObject)
    },
    getTeacherById(teacher_id) {
        return httpClient.get(`teacher/get-teacher-by-id/${teacher_id}`)
    },
    put(sendDataObject){
        return httpClient.put(`teacher/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(teacher_id) {
        return httpClient.delete(`teacher/delete/${teacher_id}`)
    },
    deleteTeacherStudyLanguage(id) {
        return httpClient.delete(`teacher/language/delete/${id}`)
    },
}

export default teacherDataService   