import httpClient from "@/services/http.service"

const foreignStudentService = {
    post(form) {
        return httpClient.post(`foreign-student/create`, form)
    },
    put(form) {
        return httpClient.put(`foreign-student/update/${form.id}`, form)
    },
    delete(foreign_student_id) {
        return httpClient.delete(`foreign-student/delete/${foreign_student_id}`)
    },
    get() {
        return httpClient.get(`foreign-student`)
    },
}

export default foreignStudentService