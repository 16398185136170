
import buildingService from "@/services/university/building.service"

const building = {
    namespaced: true,
    actions: {

        async DELETE_BUILDING({commit}, building_id) {
            try {
                const data = await buildingService.delete(building_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_BUILDING({commit, state}, form) {

            try {
                await buildingService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_BUILDING({commit, state}, form) {

            try {
                await buildingService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_BUILDINGS({commit}) {
            try {
                const {status, data} = await buildingService.get()
                if (status === 200) {
                    console.log('GET_BUILDINGS', data)
                    commit('SET_BUILDINGS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_BUILDINGS(state, data) {
            state.building_form.buildings = data
        },
    },
    state: {
        building_form: {

            buildings: []
        }
    },
    getters: {}
}

export default building