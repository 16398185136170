import httpClient from "@/services/http.service"

const universityDistanceLearningService = {
    post(form) {
        return httpClient.post(`distance-learning/create`, form)
    },
    put(form) {
        return httpClient.put(`distance-learning/update/${form.id}`, form)
    },
    delete(distance_learning_id) {
        return httpClient.delete(`distance-learning/delete/${distance_learning_id}`)
    },
    get() {
        return httpClient.get(`distance-learning`)
    },
}

export default universityDistanceLearningService