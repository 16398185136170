
import informationActivitiesService from "@/services/university/informationActivities.service";

const informationActivities = {
    namespaced: true,
    actions: {

        async DELETE_INFORMATION_ACTIVITIES({commit}, department_id) {
            try {
                const data = await informationActivitiesService.delete(department_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_INFORMATION_ACTIVITIES({commit, state}, form) {

            try {
                await informationActivitiesService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_INFORMATION_ACTIVITIES({commit, state}, form) {

            try {
                await informationActivitiesService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_INFORMATION_ACTIVITIES({commit}) {
            try {
                const {status, data} = await informationActivitiesService.get()
                if (status === 200) {
                    console.log('GET_INFORMATION_ACTIVITIES', data)
                    commit('SET_INFORMATION_ACTIVITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },



    },
    mutations: {
        SET_INFORMATION_ACTIVITIES(state, data) {
            state.informationActivities_form.informationActivities = data
        },

    },
    state: {
        informationActivities_form: {
            informationActivities: [],
        }
    },
    getters: {}
}

export default informationActivities