<!--
Это компонент для главной страницы
-->
<template>
  <div class="home text-center mt-8">
    <h5>Главная страница</h5>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
}
</script>
