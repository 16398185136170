
import foreignTeacherService from "@/services/archive/foreignTeacher.service"

const foreignTeacher = {
    namespaced: true,
    actions: {

        async DELETE_FOREIGN_TEACHER({commit}, foreign_teacher_id) {
            try {
                const data = await foreignTeacherService.delete(foreign_teacher_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_FOREIGN_TEACHER({commit, state}, form) {

            try {
                await foreignTeacherService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_FOREIGN_TEACHER({commit, state}, form) {

            try {
                await foreignTeacherService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_FOREIGN_TEACHERS({commit}) {
            try {
                const {status, data} = await foreignTeacherService.get()
                if (status === 200) {
                    console.log('GET_FOREIGN_TEACHERS', data)
                    commit('SET_FOREIGN_TEACHERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_FOREIGN_TEACHERS(state, data) {
            state.foreignTeacher_form.foreignTeachers = data
        },
    },
    state: {
        foreignTeacher_form: {

            foreignTeachers: []
        }
    },
    getters: {}
}

export default foreignTeacher