//Сервис для лабораторий
import httpClient from "@/services/http.service"

const laboratoryService = {
    post(form) {
        return httpClient.post(`laboratory/create`, form)
    },
    put(form) {
        return httpClient.put(`laboratory/update/${form.id}`, form)
    },
    delete(laboratory_id) {
        return httpClient.delete(`laboratory/delete/${laboratory_id}`)
    },
    get() {
        return httpClient.get(`laboratory/get_by_university_id`)
    },
}

export default laboratoryService