//Сервис для спортивого комплекса
import httpClient from "@/services/http.service"

const sportFacilityService = {
    post(form) {
        return httpClient.post(`sports-facility/create`, form)
    },
    put(form) {
        return httpClient.put(`sports-facility/update/${form.id}`, form)
    },
    delete(sport_facility_id) {
        return httpClient.delete(`sports-facility/delete/${sport_facility_id}`)
    },
    get() {
        return httpClient.get(`sports-facility/get_by_university_id`)
    },
}

export default sportFacilityService