//Сервис для общих запросов
import httpClient from "@/services/http.service"

const educationCommonService = {
    getDepartments() {
        return httpClient.get(`department`)
    },
    getStudyLevels() {
        return httpClient.get(`study-level`)
    },
    getNrkLevels() {
        return httpClient.get(`nrk-level`)
    },
    getOrkLevels() {
        return httpClient.get(`ork-level`)
    },
    getEducationDisciplineCommon(university_id) {
        return httpClient.get(`education-discipline/related-data?university_id=${university_id}`)
    },
    getEducationProgramCommon(university_id) {
        return httpClient.get(`education-program/related-data?university_id=${university_id}`)
    },
    getDecreeGosoTemplates() {
        return httpClient.get(`decree-goso-templates`)
    },
    getEducationFields(study_level_id) {
        return httpClient.get(`education-field?study_level_id=${study_level_id}`)
    },
    getEducationGroups(education_direction_id) {
        return httpClient.get(`education-group?education_direction_id=${education_direction_id}`)
    },
    getEducationDirections(education_field_id) {
        return httpClient.get(`education-direction?education_field_id=${education_field_id}`)
    },
}

export default educationCommonService