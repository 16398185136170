import httpClient from "@/services/http.service"

const teacherMobilityService = {
    get() {
        return httpClient.get(`teacher-mobility`)
    },
    getTeacherMobilityById(teacher_id) {
        return httpClient.get(`teacher-mobility/show/${teacher_id}`)
    },
    post(sendDataObject){
        return httpClient.post(`teacher-mobility/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`teacher-mobility/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(teacher_id) {
        return httpClient.delete(`teacher-mobility/delete/${teacher_id}`)
    },

}

export default teacherMobilityService