//Сервис для дисциплин
import httpClient from "@/services/http.service"

const studentCommonService = {
    getAll() {
        return httpClient.get(`student-data/info`)
    },
    getStudentLocationTypes() {
        return httpClient.get(`student/location-type`)
    },
    getEnrollStatuses() {
        return httpClient.get(`student/enroll-status`)
    },
    getPaymentTypes() {
        return httpClient.get(`student/payment-type`)
    },
    getStudyForms() {
        return httpClient.get(`student/study-form`)
    },
    getAfterGraduationStatuses() {
        return httpClient.get(`student/after-graduation-status`)
    },
    getSportsCategories() {
        return httpClient.get(`sport/category`)
    },
    getSports() {
        return httpClient.get(`sport`)
    },
    getDisabilityCategories() {
        return httpClient.get(`student/disability-category`)
    },
    getEmploymentStatuses() {
        return httpClient.get(`employment-status`)
    },
    getSpecialNeedTypes() {
        return httpClient.get(`student-data/special-needs-type`)
    },
}

export default studentCommonService