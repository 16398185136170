import universityExpenseService from "@/services/university/universityExpense.service"

const universityExpense = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_EXPENSE({commit}, university_expense_id) {
            try {
                const data = await universityExpenseService.delete(university_expense_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_UNIVERSITY_EXPENSE({commit, state}, form) {

            try {
                await universityExpenseService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_UNIVERSITY_EXPENSE({commit, state}, form) {

            try {
                await universityExpenseService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_UNIVERSITY_EXPENSES({commit}) {
            try {
                const {status, data} = await universityExpenseService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_EXPENSES', data)
                    commit('SET_UNIVERSITY_EXPENSES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EXPENSE_TYPES({commit}) {
            try {
                const {status, data} = await universityExpenseService.getExpenseTypes()
                if (status === 200) {
                    console.log('GET_EXPENSE_TYPES', data)
                    commit('SET_EXPENSE_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EXPENSE_FINANCING_SOURCES({commit}) {
            try {
                const {status, data} = await universityExpenseService.getExpenseFinancingSources()
                if (status === 200) {
                    console.log('GET_EXPENSE_FINANCING_SOURCES', data)
                    commit('SET_EXPENSE_FINANCING_SOURCES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {
        SET_UNIVERSITY_EXPENSES(state, data) {
            state.universityExpense_form.universityExpenses = data
        },
        SET_EXPENSE_TYPES(state, data) {
            state.universityExpense_form.expenseTypes = data
        },
        SET_EXPENSE_FINANCING_SOURCES(state, data) {
            state.universityExpense_form.expenseFinancingSources = data
        },

    },
    state: {
        universityExpense_form: {
            universityExpenses: [],
            expenseTypes: [],
            expenseFinancingSources: [],
        }
    },
    getters: {}
}

export default universityExpense