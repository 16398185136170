
import academicMobilityService from "@/services/archive/academicMobility.service"

const academicMobility = {
    namespaced: true,
    actions: {

        async DELETE_ACADEMIC_MOBILITY({commit}, academic_mobility_id) {
            try {
                const data = await academicMobilityService.delete(academic_mobility_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_ACADEMIC_MOBILITY({commit, state}, form) {

            try {
                await academicMobilityService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ACADEMIC_MOBILITY({commit, state}, form) {

            try {
                await academicMobilityService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ACADEMIC_MOBILITIES({commit}) {
            try {
                const {status, data} = await academicMobilityService.get()
                if (status === 200) {
                    console.log('GET_ACADEMIC_MOBILITIES', data)
                    commit('SET_ACADEMIC_MOBILITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_ACADEMIC_MOBILITIES(state, data) {
            state.academicMobility_form.academicMobilities = data
        },
    },
    state: {
        academicMobility_form: {

            academicMobilities: []
        }
    },
    getters: {}
}

export default academicMobility