import httpClient from "@/services/http.service"

const igpaService = {
    post(form) {
        return httpClient.post(`igpa/create`, form)
    },
    put(form) {
        return httpClient.put(`igpa/update/${form.id}`, form)
    },
    delete(igpa_id) {
        return httpClient.delete(`igpa/delete/${igpa_id}`)
    },
    get() {
        return httpClient.get(`igpa`)
    },
}

export default igpaService