//Сервис для цифровых ресурсов
import httpClient from "@/services/http.service"

const digitalResourceService = {
    post(form) {
        return httpClient.post(`digital-resource/create`, form)
    },
    put(form) {
        return httpClient.put(`digital-resource/update/${form.id}`, form)
    },
    delete(digital_resource_id) {
        return httpClient.delete(`digital-resource/delete/${digital_resource_id}`)
    },
    get() {
        return httpClient.get(`digital-resource`)
    },
}

export default digitalResourceService