
import additionalSubunitService from "@/services/university/additionalSubunit.service"

const additionalSubunit = {
    namespaced: true,
    actions: {

        async DELETE_ADDITIONAL_SUBUNIT({commit}, additional_subunit_id) {
            try {
                const data = await additionalSubunitService.delete(additional_subunit_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_ADDITIONAL_SUBUNIT({commit, state}, form) {

            try {
                await additionalSubunitService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ADDITIONAL_SUBUNIT({commit, state}, form) {
            try {
                await additionalSubunitService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async GET_ADDITIONAL_SUBUNITS({commit}) {
            try {
                const {status, data} = await additionalSubunitService.get()
                if (status === 200) {
                    console.log('GET_ADDITIONAL_SUBUNITS', data)
                    commit('SET_ADDITIONAL_SUBUNITS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_SUBUNIT_RESPONSIBILITIES({commit}) {
            try {
                const {status, data} = await additionalSubunitService.getSubunitResponsibilities()
                if (status === 200) {
                    console.log('GET_SUBUNIT_RESPONSIBILITIES', data)
                    commit('SET_SUBUNIT_RESPONSIBILITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_ADDITIONAL_SUBUNITS(state, data) {
            state.additionalSubunit_form.accreditations = data
        },
        SET_SUBUNIT_RESPONSIBILITIES(state, data) {
            state.additionalSubunit_form.subunitResponsibilities = data
        },
    },
    state: {
        additionalSubunit_form: {
            additionalSubunits: [],
            subunitResponsibilities: [],
        }
    },
    getters: {}
}

export default additionalSubunit