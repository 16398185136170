import onlineLearningService from "@/services/university/onlineLearning.service";

const onlineLearning = {
    namespaced: true,
    actions: {

        async DELETE_ONLINE_LEARNING({commit}, obj_id) {
            try {
                const data = await onlineLearningService.delete(obj_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_ONLINE_LEARNING({commit, state}, form) {

            try {
                await onlineLearningService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ONLINE_LEARNING({commit, state}, form) {

            try {
                await onlineLearningService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ONLINE_LEARNING({commit}) {
            try {
                const {status, data} = await onlineLearningService.get()
                if (status === 200) {
                    console.log('GET_ONLINE_LEARNING', data)
                    commit('SET_ONLINE_LEARNING', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },



    },
    mutations: {
        SET_ONLINE_LEARNING(state, data) {
            state.onlineLearning_form.onlineLearning = data
        },

    },
    state: {
        onlineLearning_form: {
            onlineLearning: [],
        }
    },
    getters: {}
}

export default onlineLearning