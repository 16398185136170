import internationalProjectService from "@/services/university/internationalProject.service"

const internationalProject = {
    namespaced: true,
    actions: {

        async DELETE_INTERNATIONAL_PROJECT({commit}, international_project_id) {
            try {
                const data = await internationalProjectService.delete(international_project_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_INTERNATIONAL_PROJECT({commit, state}, form) {

            try {
                await internationalProjectService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_INTERNATIONAL_PROJECT({commit, state}, form) {

            try {
                await internationalProjectService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async GET_INTERNATIONAL_PROJECTS({commit}) {
            try {
                const {status, data} = await internationalProjectService.get()
                if (status === 200) {
                    console.log('GET_INTERNATIONAL_PROJECTS', data)
                    commit('SET_INTERNATIONAL_PROJECTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async GET_ALL_INTERNATIONAL_PROJECTS({commit}, {university_id, size, page}) {
            try {
                const {status, data} = await internationalProjectService.getAll(university_id, size, page)
                if (status === 200) {
                    console.log('GET_ALL_INTERNATIONAL_PROJECTS', data)
                    commit('SET_ALL_INTERNATIONAL_PROJECTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_INTERNATIONAL_PROJECT_FINANCING({commit}) {
            try {
                const {status, data} = await internationalProjectService.getInternationalProjectFinancing()
                if (status === 200) {
                    console.log('GET_INTERNATIONAL_PROJECT_FINANCING', data)
                    commit('SET_INTERNATIONAL_PROJECT_FINANCING', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_INTERNATIONAL_PROJECT_TYPE({commit}) {
            try {
                const {status, data} = await internationalProjectService.getInternationalProjectType()
                if (status === 200) {
                    console.log('GET_INTERNATIONAL_PROJECT_TYPE', data)
                    commit('SET_INTERNATIONAL_PROJECTS_TYPE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },



    },
    mutations: {

        SET_INTERNATIONAL_PROJECTS(state, data) {
            state.internationalProject_form.internationalProjects = data
        },
        SET_INTERNATIONAL_PROJECTS_TYPE(state, data) {
            state.internationalProject_form.internationalProjectType = data
        },
        SET_INTERNATIONAL_PROJECT_FINANCING(state, data) {
            state.internationalProject_form.internationalProjectFinancing = data
        },
        SET_ALL_INTERNATIONAL_PROJECTS(state, data) {
            state.internationalProject_form.allInternationalProject = data
        },
    },
    state: {
        internationalProject_form: {

            internationalProjects: [],
            internationalProjectFinancing: [],
            internationalProjectType: [],
            allInternationalProject: [],
        }
    },
    getters: {}
}

export default internationalProject