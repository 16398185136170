//Хранилище данных справочников
import commonService from "@/services/common/common.service";

const common = {
    namespaced: true,
    actions: {
        async GET_ALL_COMMONS({commit}) {
            try {
                const {status, data} = await commonService.getAll()
                if (status === 200) {
                    commit('SET_ALL_COMMONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_NATIONALITIES({commit}) {
            try {
                const {status, data} = await commonService.getNationalities()
                if (status === 200) {
                    commit('SET_NATIONALITIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_CITIZENSHIPS({commit}) {
            try {
                const {status, data} = await commonService.getCitizenships()
                if (status === 200) {
                    commit('SET_CITIZENSHIPS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ENGLISH_LEVELS({commit}) {
            try {
                const {status, data} = await commonService.getEnglishLevels()
                if (status === 200) {
                    commit('SET_ENGLISH_LEVELS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_GENDERS({commit}) {
            try {
                const {status, data} = await commonService.getGenders()
                if (status === 200) {
                    commit('SET_GENDERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_LANGUAGES({commit}) {
            try {
                const {status, data} = await commonService.getLanguages()
                if (status === 200) {
                    commit('SET_LANGUAGES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_COUNTRIES({commit}) {
            try {
                const {status, data} = await commonService.getCountries()
                if (status === 200) {
                    commit('SET_COUNTRIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_STUDY_FORMS({commit}) {
            try {
                const {status, data} = await commonService.getStudyForms()
                if (status === 200) {
                    commit('SET_STUDY_FORMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {
        SET_ALL_COMMONS(state, data) {
            state.common_form.all = data
        },
        SET_NATIONALITIES(state, data) {
            state.common_form.nationalities = data
        },
        SET_CITIZENSHIPS(state, data) {
            state.common_form.citizenships = data
        },
        SET_ENGLISH_LEVELS(state, data) {
            state.common_form.englishLevels = data
        },
        SET_GENDERS(state, data) {
            state.common_form.genders = data
        },
        SET_LANGUAGES(state, data) {
            state.common_form.languages = data
        },
        SET_COUNTRIES(state, data) {
            state.common_form.countries = data
        },
        SET_STUDY_FORMS(state, data) {
            state.common_form.studyForms = data
        },
    },
    state: {
        common_form: {
            all: {},
            nationalities: [],
            citizenships: [],
            englishLevels: [],
            genders: [],
            languages: [],
            countries: [],
            studyForms: [],
        }

    },
    getters: {}
}

export default common