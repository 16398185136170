import teacherDataService from "@/services/teacher/teacherData.service";

const teacherData = {
    namespaced: true,
    actions: {
        async GET_TEACHERS({commit}, filterObject) {
            try {
                const {
                    status,
                    data
                } = await teacherDataService.get(filterObject.page, filterObject.name_doc, filterObject.size, filterObject.teacher_type)
                if (status === 200) {
                    commit('SET_TEACHERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_TEACHER_BY_ID({commit}, teacher_id) {
            try {
                const {
                    status,
                    data
                } = await teacherDataService.getTeacherById(teacher_id)
                if (status === 200) {
                    commit('SET_TEACHER_BY_ID', data)
                    return data;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async POST_TEACHER_DATA({commit}, sendDataObject) {
            try {
                const {status, data} = await teacherDataService.post(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_TEACHER_DATA({commit}, sendDataObject) {
            try {
                const {status, data} = await teacherDataService.put(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }

            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_TEACHER_DATA({commit}, student_id) {
            try {
                const data = await teacherDataService.delete(student_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TEACHERS_RELATED_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await teacherDataService.getRelatedData()
                if (status === 200) {
                    commit('SET_TEACHERS_RELATED_DATA', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async DELETE_TEACHER_STUDY_LANGUAGE({commit}, id) {
            try {
                const {status, data} = await teacherDataService.deleteTeacherStudyLanguage(id);
                if (status === 200) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
    },
    mutations: {
        SET_TEACHERS(state, data) {
            state.teacherData_form.teachers = data
        },
        SET_TEACHERS_RELATED_DATA(state, data) {
            state.teacherData_form.relatedData = data
        },
        SET_TEACHER_BY_ID(state, data) {
            state.teacherData_form.teacherById = data
        },
    },
    state: {
        teacherData_form: {
            teachers: [],
            relatedData: [],
            teacherById: {},
        }

    },
    getters: {}
}

export default teacherData