//Сервис для дополнительного подразделения
import httpClient from "@/services/http.service"

const additionalSubunitService = {
    post(form) {
        return httpClient.post(`additional-subunit/create`, form)
    },
    put(form) {
        return httpClient.put(`additional-subunit/update/${form.id}`, form)
    },
    delete(additional_subunit_id) {
        return httpClient.delete(`additional-subunit/delete/${additional_subunit_id}`)
    },
    get() {
        return httpClient.get(`additional-subunit`)
    },
    getSubunitResponsibilities() {
        return httpClient.get(`subunit-responsibility`)
    },
}

export default additionalSubunitService