//Хранилище данных для университетов

import universityService from "@/services/university/university.service";

const university = {
  namespaced: true,
  actions: {
    async DELETE_UNIVERSITY({commit}, foreign_university_id) {
      try {
        const data = await universityService.delete(foreign_university_id)
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_UNIVERSITY({commit, state}, form) {
      try {
        await universityService.put(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_UNIVERSITY({commit, state}, form) {
      try {
        await universityService.post(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_UNIVERSITIES({commit}) {
      try {
        const {status, data} = await universityService.get()
        if (status === 200) {
          commit('SET_UNIVERSITIES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_UNIVERSITY({commit}) {
      try {
        const {status, data} = await universityService.getUniversity()
        if (status === 200) {
          commit('SET_UNIVERSITY', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_REGIONS({commit}) {
      try {
        const {status, data} = await universityService.getRegions()
        if (status === 200) {
          commit('SET_REGIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_SCIENCE_FUNDING_TYPES({commit}) {
      try {
        const {status, data} = await universityService.getScienceFundingTypes()
        if (status === 200) {
          commit('SET_SCIENCE_FUNDING_TYPES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_UNIVERSITY_TYPES({commit}) {
      try {
        const {status, data} = await universityService.getUniversityTypes()
        if (status === 200) {
          commit('SET_UNIVERSITY_TYPES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

  },
  mutations: {
    SET_UNIVERSITIES(state, data) {
      state.universities = data
    },
    SET_UNIVERSITY(state, data) {
      state.university = data
    },
    SET_REGIONS(state, data) {
      state.regions = data
    },
    SET_SCIENCE_FUNDING_TYPES(state, data) {
      state.scienceFundingTypes = data
    },
    SET_UNIVERSITY_TYPES(state, data) {
      state.universityTypes = data
    },
  },
  state: {
    universities: [],
    university: {},
    regions: [],
    scienceFundingTypes: [],
    universityTypes: []
  },
  getters: {}
}

export default university
