//Сервис для органа оргиназации
import httpClient from "@/services/http.service"

const accreditationBodyService = {
    post(form) {
        return httpClient.post(`accreditation/body/create`, form)
    },
    put(form) {
        return httpClient.put(`accreditation/body/update/${form.id}`, form)
    },
    delete(accreditation_body_id) {
        return httpClient.delete(`accreditation/body/delete/${accreditation_body_id}`)
    },
    get() {
        return httpClient.get(`accreditation/body`)
    },
    getTypes() {
        return httpClient.get(`accreditation/body/type`)
    },
}

export default accreditationBodyService