import researchWorkService from "@/services/university/researchWorks.service";

const researchWork = {
    namespaced: true,
    actions: {

        async DELETE_RESEARCH_WORK({commit}, research_work_id) {
            try {
                const data = await researchWorkService.delete(research_work_id);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_RESEARCH_WORK({commit, state}, form) {

            try {
                await researchWorkService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_RESEARCH_WORK({commit, state}, form) {

            try {
                await researchWorkService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_RESEARCH_WORK_TYPES({commit}) {
            try {
                const {status, data} = await researchWorkService.getResearchWorkTypes()
                if (status === 200) {
                    commit('SET_RESEARCH_WORK_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_RESEARCH_WORKS({commit}, filterObject) {
            try {
                const {status, data} = await researchWorkService.get(filterObject.page)
                if (status === 200) {
                    commit('SET_RESEARCH_WORKS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {
        SET_RESEARCH_WORK_TYPES(state, data) {
            state.researchWorksTypes = data
        },
        SET_RESEARCH_WORKS(state, data) {
            state.researchWorks = data
        },
    },
    state: {
        researchWorks: [],
        researchWorksTypes: [],
    },
}

export default researchWork