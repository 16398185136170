
import joinEducationProgramService from "@/services/education/joinEducationProgram.service";

const joinEducationProgram = {
    namespaced: true,
    actions: {

        async DELETE_JOIN_EDUCATION_PROGRAM({commit}, obj_id) {
            try {
                const data = await joinEducationProgramService.delete(obj_id);
                if (data) {
                    console.log(data, 'discipline deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_JOIN_EDUCATION_PROGRAM({commit, state}, form) {

            try {
                await joinEducationProgramService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_JOIN_EDUCATION_PROGRAM({commit, state}, form) {

            try {
                await joinEducationProgramService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_JOIN_EDUCATION_PROGRAM({commit}) {
            try {
                const {status, data} = await joinEducationProgramService.get()
                if (status === 200) {
                    console.log('GET_EDUCATION_DISCIPLINES', data)
                    commit('SET_JOIN_EDUCATION_PROGRAM', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_JOIN_EDUCATION_PROGRAM(state, data) {
            state.joinEducationProgram_form.joinEducationProgram = data
        },
    },
    state: {
        joinEducationProgram_form: {
            joinEducationProgram: []
        }
    },
    getters: {}
}

export default joinEducationProgram