
import distanceLearningService from "@/services/archive/distanceLearning.service"

const distanceLearning = {
    namespaced: true,
    actions: {

        async DELETE_DISTANCE_LEARNING({commit}, distance_learning_id) {
            try {
                const data = await distanceLearningService.delete(distance_learning_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_DISTANCE_LEARNING({commit, state}, form) {

            try {
                await distanceLearningService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_DISTANCE_LEARNING({commit, state}, form) {

            try {
                await distanceLearningService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DISTANCE_LEARNINGS({commit}) {
            try {
                const {status, data} = await distanceLearningService.get()
                if (status === 200) {
                    console.log('GET_DISTANCE_LEARNINGS', data)
                    commit('SET_DISTANCE_LEARNINGS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_DISTANCE_LEARNINGS(state, data) {
            state.distanceLearning_form.distanceLearnings = data
        },
    },
    state: {
        distanceLearning_form: {

            distanceLearnings: []
        }
    },
    getters: {}
}

export default distanceLearning