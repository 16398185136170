import httpClient from "@/services/http.service"

const universityMOOC = {
    post(form) {
        return httpClient.post(`mooc/create`, form)
    },
    put(form) {
        return httpClient.put(`mooc/update/${form.id}`, form)
    },
    delete(id) {
        return httpClient.delete(`mooc/delete/${id}`)
    },
    get() {
        return httpClient.get(`mooc`)
    },
}

export default universityMOOC