
import universityRepresentationService from "@/services/university/universityRepresentation.service"
import publicationService from "@/services/university/publication.service";

const universityRepresentation = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_REPRESENTATION({commit}, university_representation_id) {
            try {
                const data = await universityRepresentationService.delete(university_representation_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_UNIVERSITY_REPRESENTATION({commit, state}, form) {

            try {
                await universityRepresentationService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_UNIVERSITY_REPRESENTATION({commit, state}, form) {

            try {
                await universityRepresentationService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_UNIVERSITY_REPRESENTATIONS({commit}) {
            try {
                const {status, data} = await universityRepresentationService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_REPRESENTATIONS', data)
                    commit('SET_UNIVERSITY_REPRESENTATIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_REPRESENTATION_TYPES({commit}) {
            try {
                const {status, data} = await universityRepresentationService.getRepresentationTypes()
                if (status === 200) {
                    console.log('GET_REPRESENTATION_TYPES', data)
                    commit('SET_REPRESENTATION_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_REPRESENTATION_AGREEMENT_TYPES({commit}) {
            try {
                const {status, data} = await universityRepresentationService.getRepresentationAgreementTypes()
                if (status === 200) {
                    console.log('GET_REPRESENTATION_AGREEMENT_TYPES', data)
                    commit('SET_REPRESENTATION_AGREEMENT_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_BRANCH_TYPES({commit}) {
            try {
                const {status, data} = await universityRepresentationService.getBranchTypes()
                if (status === 200) {
                    console.log('GET_BRANCH_TYPES', data)
                    commit('SET_BRANCH_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_REPRESENTATION_BY_ID({commit}, university_representation_id) {
            try {
                const {status, data} = await universityRepresentationService.getRepresentationById(university_representation_id)
                if (status === 200) {
                    console.log('GET_UNIVERSITY_REPRESENTATIONS', data)
                    commit('SET_UNIVERSITY_REPRESENTATIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ALL_REPRESENTATION({commit}, {university_id, size, page}) {
            try {
                const {status, data} = await universityRepresentationService.getAllRepresentation(university_id, size, page)
                if (status === 200) {
                    console.log('GET_ALL_REPRESENTATION', data)
                    commit('SET_ALL_REPRESENTATION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },


    },
    mutations: {
        SET_UNIVERSITY_REPRESENTATIONS(state, data) {
            state.universityRepresentation_form.universityRepresentations = data
        },
        SET_REPRESENTATION_TYPES(state, data) {
            state.universityRepresentation_form.representationTypes = data
        },
        SET_REPRESENTATION_AGREEMENT_TYPES(state, data) {
            state.universityRepresentation_form.representationAgreementTypes = data
        },
        SET_BRANCH_TYPES(state, data) {
            state.universityRepresentation_form.branchTypes = data
        },
        SET_ALL_REPRESENTATION(state, data) {
            state.universityRepresentation_form.allUniversityRepresentations = data
        },
    },
    state: {
        universityRepresentation_form: {

            universityRepresentations: [],
            allUniversityRepresentations: {},
            representationTypes: [],
            representationAgreementTypes: [],
            branchTypes: [],
        }
    },
    getters: {}
}

export default universityRepresentation