
import journalService from "@/services/university/journal.service";

const journal = {
    namespaced: true,
    actions: {

        async DELETE_JOURNAL({commit}, department_id) {
            try {
                const data = await journalService.delete(department_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_JOURNAL({commit, state}, form) {

            try {
                await journalService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_JOURNAL({commit, state}, form) {

            try {
                await journalService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_JOURNAL({commit}) {
            try {
                const {status, data} = await journalService.get()
                if (status === 200) {
                    console.log('GET_JOURNAL', data)
                    commit('SET_JOURNAL', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_SCIENTIFIC({commit}) {
            try {
                const {status, data} = await journalService.getScientific()
                if (status === 200) {
                    console.log('GET_SCIENTIFIC', data)
                    commit('SET_SCIENTIFIC', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_JOURNAL(state, data) {
            state.journal_form.journals = data
        },
        SET_SCIENTIFIC(state, data) {
            state.journal_form.scientific = data
        },
    },
    state: {
        journal_form: {
            journals: [],
            scientific:[]
        }
    },
    getters: {}
}

export default journal