import internationalContractsService from "@/services/university/internationalContracts.service";

const internationalContracts = {
    namespaced: true,
    actions: {
        async DELETE_INTERNATIONAL_CONTRACTS({commit}, international_contracts_id) {
            try {
                const data = await internationalContractsService.delete(international_contracts_id)
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_INTERNATIONAL_CONTRACTS({commit, state}, form) {
            try {
                await internationalContractsService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_INTERNATIONAL_CONTRACTS({commit, state}, form) {

            try {
                await internationalContractsService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_INTERNATIONAL_CONTRACTS({commit}) {
            try {
                const {status, data} = await internationalContractsService.get()
                if (status === 200) {
                    commit('SET_INTERNATIONAL_CONTRACTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ALL_INTERNATIONAL_CONTRACTS({commit}, {university_id, size, page}) {
            try {
                const {status, data} = await internationalContractsService.getAll(university_id, size, page)
                if (status === 200) {
                    commit('SET_ALL_INTERNATIONAL_CONTRACTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {
        SET_INTERNATIONAL_CONTRACTS(state, data) {
            state.internationalContracts_form.internationalContracts = data
        },
        SET_ALL_INTERNATIONAL_CONTRACTS(state, data) {
            state.internationalContracts_form.allInternationalContracts = data
        },
        SET_INTERNATIONAL_CONTRACTS_FOREIGN_UNIVERSITY(state, data) {
            state.internationalContracts_form.foreignUniversity = data
        },

    },
    state: {
        internationalContracts_form: {
            internationalContracts: [],
            foreignUniversity: [],
            allInternationalContracts: [],
        }
    },
    getters: {}
}

export default internationalContracts