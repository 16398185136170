import httpClient from "@/services/http.service"

const journalService = {
    get() {
        return httpClient.get(`journal`)
    },
    getScientific() {
        return httpClient.get(`scientific-database`)
    },
    post(sendDataObject){
        return httpClient.post(`journal/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`journal/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(journal_id) {
        return httpClient.delete(`journal/delete/${journal_id}`)
    },
}

export default journalService