import httpClient from "@/services/http.service"

const joinEducationProgramService = {
    post(form) {
        return httpClient.post(`joint-education-program/create`, form)
    },
    put(form) {
        return httpClient.put(`joint-education-program/update/${form.id}`, form)
    },
    delete(obj_id) {
        return httpClient.delete(`joint-education-program/delete/${obj_id}`)
    },
    get() {
        return httpClient.get(`joint-education-program`)
    },

}

export default joinEducationProgramService