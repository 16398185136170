
import accreditationBodyQAService from "@/services/university/accreditationBodyQA.service"

const accreditationBodyQA = {
    namespaced: true,
    actions: {

        async DELETE_ACCREDITATION_BODY_QA({commit}, accreditation_body_qa_id) {
            try {
                const data = await accreditationBodyQAService.delete(accreditation_body_qa_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_ACCREDITATION_BODY_QA({commit, state}, form) {

            try {
                await accreditationBodyQAService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ACCREDITATION_BODY_QA({commit, state}, form) {

            try {
                await accreditationBodyQAService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ACCREDITATION_BODY_QAS({commit}) {
            try {
                const {status, data} = await accreditationBodyQAService.get()
                if (status === 200) {
                    console.log('GET_ACCREDITATION_BODY_QAS', data)
                    commit('SET_ACCREDITATION_BODY_QAS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_QUALITY_ASSURANCE_ORGANIZATIONS({commit}) {
            try {
                const {status, data} = await accreditationBodyQAService.getQualityAssuranceOrganizations()
                if (status === 200) {
                    console.log('GET_QUALITY_ASSURANCE_ORGANIZATIONS', data)
                    commit('SET_QUALITY_ASSURANCE_ORGANIZATIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_ACCREDITATION_BODY_QAS(state, data) {
            state.accreditationBodyQA_form.accreditationBodyQAs = data
        },
        SET_QUALITY_ASSURANCE_ORGANIZATIONS(state, data) {
            state.accreditationBodyQA_form.qualityAssuranceOrganizations = data
        },
    },
    state: {
        accreditationBodyQA_form: {

            accreditationBodyQAs: [],
            qualityAssuranceOrganizations: []
        }
    },
    getters: {}
}

export default accreditationBodyQA