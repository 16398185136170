import httpClient from "@/services/http.service"

const informationActivitiesService = {
    get() {
        return httpClient.get(`information-activities`)
    },
    getScientific() {
        return httpClient.get(`scientific-database`)
    },
    post(sendDataObject){
        return httpClient.post(`information-activities/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`information-activities/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(informationActivities_id) {
        return httpClient.delete(`information-activities/delete/${informationActivities_id}`)
    },
}

export default informationActivitiesService