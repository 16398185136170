import httpClient from "@/services/http.service"

const trilingualEducationService = {
    post(form) {
        return httpClient.post(`trilingual-education/create`, form)
    },
    put(form) {
        return httpClient.put(`trilingual-education/update/${form.id}`, form)
    },
    delete(trilingual_education_id) {
        return httpClient.delete(`trilingual-education/delete/${trilingual_education_id}`)
    },
    get() {
        return httpClient.get(`trilingual-education`)
    },
}

export default trilingualEducationService