import httpClient from "@/services/http.service"

const academicMobilityService = {
    post(form) {
        return httpClient.post(`academic-mobility/create`, form)
    },
    put(form) {
        return httpClient.put(`academic-mobility/update/${form.id}`, form)
    },
    delete(academic_mobility_id) {
        return httpClient.delete(`academic-mobility/delete/${academic_mobility_id}`)
    },
    get() {
        return httpClient.get(`academic-mobility`)
    },
}

export default academicMobilityService