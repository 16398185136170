import httpClient from "@/services/http.service"

const quotaContingentService = {
    post(form) {
        return httpClient.post(`quota-contingent/create`, form)
    },
    put(form) {
        return httpClient.put(`quota-contingent/update/${form.id}`, form)
    },
    delete(quota_contingent_id) {
        return httpClient.delete(`quota-contingent/delete/${quota_contingent_id}`)
    },
    get() {
        return httpClient.get(`quota-contingent`)
    },
}

export default quotaContingentService