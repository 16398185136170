
import increasingCompetenceService from "@/services/university/increasingCompetence.service";

const increasingCompetence = {
    namespaced: true,
    actions: {

        async DELETE_INCREASING_COMPETENCE({commit}, increasingCompetence_id) {
            try {
                const data = await increasingCompetenceService.delete(increasingCompetence_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_INCREASING_COMPETENCE({commit, state}, form) {

            try {
                await increasingCompetenceService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_INCREASING_COMPETENCE({commit, state}, form) {

            try {
                await increasingCompetenceService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_INCREASING_COMPETENCE({commit}) {
            try {
                const {status, data} = await increasingCompetenceService.get()
                if (status === 200) {
                    console.log('GET_JOURNAL', data)
                    commit('SET_INCREASING_COMPETENCE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },



    },
    mutations: {
        SET_INCREASING_COMPETENCE(state, data) {
            state.increasingCompetence_form.increasingCompetence = data
        },

    },
    state: {
        increasingCompetence_form: {
            increasingCompetence: [],
        }
    },
    getters: {}
}

export default increasingCompetence