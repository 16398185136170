import httpClient from "@/services/http.service"

const silverEducationNonformalService = {
    post(form) {
        return httpClient.post(`silver-education-nonformal/create`, form)
    },
    put(form) {
        return httpClient.put(`silver-education-nonformal/update/${form.id}`, form)
    },
    delete(silver_education_nonformal_id) {
        return httpClient.delete(`silver-education-nonformal/delete/${silver_education_nonformal_id}`)
    },
    get() {
        return httpClient.get(`silver-education-nonformal`)
    },
}

export default silverEducationNonformalService