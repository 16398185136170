//Хранилище данных для ОП
import educationProgramService from "@/services/education/educationProgram.service"

const educationProgram = {
    namespaced: true,
    actions: {
        async GET_EDUCATION_FIELDS({commit}) {
            try {
                const {status, data} = await educationProgramService.getEducationFields()
                if (status === 200) {
                    console.log('GET_EDUCATION_FIELDS', data)
                    commit('SET_EDUCATION_FIELDS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_EDUCATION_DIRECTIONS({commit}) {
            try {
                const {status, data} = await educationProgramService.getEducationDirections()
                if (status === 200) {
                    console.log('GET_EDUCATION_DIRECTIONS', data.data)
                    commit('SET_EDUCATION_DIRECTIONS', data.data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_EDUCATION_GROUPS({commit}) {
            try {
                const {status, data} = await educationProgramService.getEducationGroups()
                if (status === 200) {
                    console.log('GET_EDUCATION_GROUPS', data.data)
                    commit('SET_EDUCATION_GROUPS', data.data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async POST_EDUCATION_PROGRAM({commit, state}, form) {

            try {
                await educationProgramService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_EDUCATION_PROGRAM({commit, state}, form) {

            try {
                await educationProgramService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_EDUCATION_PROGRAM({commit}, education_program_id) {
            try {
                const data = await educationProgramService.delete(education_program_id);
                if (data) {
                    console.log(data, 'education program deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_EDUCATION_PROGRAMS({commit}) {
            try {
                const {status, data} = await educationProgramService.get()
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAMS', data)
                    commit('SET_EDUCATION_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_APPLICATION_EDUCATION_PROGRAMS({commit}) {
            try {
                const {status, data} = await educationProgramService.getApplicationEducationPrograms()
                if (status === 200) {
                    console.log('GET_APPLICATION_EDUCATION_PROGRAMS', data)
                    commit('SET_APPLICATION_EDUCATION_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_EDUCATION_PROGRAM_BY_CODE_OR_NAME({commit}, ep) {
            try {
                const {status, data} = await educationProgramService.getEducationProgramByCodeOrName(ep)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_BY_CODE_OR_NAME', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_PROGRAM_INFO({commit}, education_program_id) {
            try {
                const {status, data} = await educationProgramService.getEducationProgramInfo(education_program_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_INFO', data)
                    //commit('SET_EDUCATION_GROUPS', data.data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_PROGRAM_BY_ID({commit}, education_program_id) {
            try {
                const {status, data} = await educationProgramService.getEducationProgramById(education_program_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_BY_ID', data)
                    commit('SET_EDUCATION_PROGRAM_BY_ID', data)
                    return data;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async GET_SEARCH_EDUCATION_PROGRAM_UNIVERSITIES({commit}, form) {
            try {
                const {status, data} = await educationProgramService.getEducationProgramUniversities(form.searching_university_name, form.is_foreign)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_UNIVERSITIES', data)
                    form.is_foreign
                        ? commit('SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES', data)
                        : commit('SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_PROGRAM_UNIVERSITY({commit}, form) {
            try {
                const {status, data} = await educationProgramService.getEducationProgramUniversityById(form.university_id, form.is_foreign)
                if (status === 200) {
                    form.is_foreign
                        ? commit('SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES', data)
                        : commit('SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_EDUCATION_FIELDS(state, data) {
            state.educationProgram_form.educationFields = data
        },
        SET_EDUCATION_DIRECTIONS(state, data) {
            state.educationProgram_form.educationDirections = data
        },
        SET_EDUCATION_GROUPS(state, data) {
            state.educationProgram_form.educationGroups = data
        },
        SET_EDUCATION_PROGRAMS(state, data) {
            state.educationProgram_form.educationPrograms = data
        },
        SET_EDUCATION_PROGRAM_BY_ID(state, data) {
            state.educationProgram_form.educationProgram = data
        },
        SET_APPLICATION_EDUCATION_PROGRAMS(state, data) {
            state.educationProgram_form.applicationEducationPrograms = data
        },
        SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES(state, data) {
            state.educationProgram_form.nationalUniversities = data
            console.log('SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES', state.educationProgram_form.nationalUniversities)
        },
        SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES(state, data) {
            state.educationProgram_form.foreignUniversities = data
            console.log('SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES', state.educationProgram_form.foreignUniversities)
        },
    },
    state: {
        educationProgram_form: {
            educationFields: [],
            educationDirections: [],
            educationGroups: [],
            educationPrograms: [],
            educationProgram: {},
            applicationEducationPrograms: [
                {
                    id: 1,
                    code: '6B0015',
                    name: 'Информационные системы',
                    university_name: 'UIB',
                    university_id: 1,
                    study_level: 'Бакалавриат',
                    study_level_id: 1,
                    type: 'Новый ОП',
                    type_id: 1,
                    application_status: '123',
                    application_status_id: 1,
                }
            ],
            nationalUniversities: [],
            foreignUniversities: []
        }
    },
    getters: {}
}

export default educationProgram