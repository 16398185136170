import teacherMobilityService from "@/services/teacher/teacherMobility.service";
import internationalContractsService from "@/services/university/internationalContracts.service";


const teacherMobility = {
    namespaced: true,
    actions: {
        async GET_TEACHER_MOBILITY({commit}) {
            try {
                const {
                    status,
                    data
                } = await teacherMobilityService.get()
                if (status === 200) {
                    commit('SET_TEACHER_MOBILITY', data)
                    return data
                }
            } catch (e) {
                console.log(e)
                return false
                // commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_TEACHER_MOBILITY_BY_ID({commit}, foreign_teacher_id) {
            try {
                const {
                    status,
                    data
                } = await teacherMobilityService.getTeacherMobilityById(foreign_teacher_id)
                if (status === 200) {
                    commit('SET_FOREIGN_TEACHER', data)
                    console.log(typeof(data))
                }
            } catch (e) {
                console.log(e)
                return false
                // commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async POST_TEACHER_MOBILITY({commit}, sendDataObject) {
            try {
                const {status, data} = await teacherMobilityService.post(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_TEACHER_MOBILITY({commit}, sendDataObject) {
            try {
                const {status, data} = await teacherMobilityService.put(sendDataObject)
                if (status === 200) {
                    return {
                        success: true,
                        data
                    }
                }

            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_TEACHER_MOBILITY({commit}, international_contracts_id) {
            try {
                const data = await teacherMobilityService.delete(international_contracts_id)
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },



    },
    mutations: {
        SET_TEACHER_MOBILITY(state, data) {
            state.teacherMobility_form.teacherMobilityData = data
        },
    },
    state: {
        teacherMobility_form: {
            teacherMobilityData: [],
        }

    },
    getters: {}
}

export default teacherMobility