
import igpaService from "@/services/archive/igpa.service"

const igpa = {
    namespaced: true,
    actions: {

        async DELETE_IGPA({commit}, igpa_id) {
            try {
                const data = await igpaService.delete(igpa_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_IGPA({commit, state}, form) {

            try {
                await igpaService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_IGPA({commit, state}, form) {

            try {
                await igpaService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_IGPAS({commit}) {
            try {
                const {status, data} = await igpaService.get()
                if (status === 200) {
                    console.log('GET_IGPAS', data)
                    commit('SET_IGPAS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_IGPAS(state, data) {
            state.igpa_form.igpas = data
        },
    },
    state: {
        igpa_form: {

            igpas: []
        }
    },
    getters: {}
}

export default igpa