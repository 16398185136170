import httpClient from "@/services/http.service"

const teacherPublicationService = {
    get(page = 1,) {
        return httpClient.get(`teacher/publication?page=` + page)
    },
    post(sendDataObject){
        return httpClient.post(`teacher/publication/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`teacher/publication/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(publication_id) {
        return httpClient.delete(`teacher/publication/delete/${publication_id}`)
    },
    getSearchTeacherByName(name_doc) {
        return httpClient.get(`teacher/search?name_doc=${name_doc}`)
    },
    getSearchPublicationByName(name) {
        return httpClient.get(`publication-search?name=${name}`)
    },
    getPublicationById(id) {
        return httpClient.get(`publication/${id}`)
    },
}

export default teacherPublicationService