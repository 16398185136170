import httpClient from "@/services/http.service"

const accreditationBodyQAService = {
    post(form) {
        return httpClient.post(`accreditation/body/qa/create`, form)
    },
    put(form) {
        return httpClient.put(`accreditation/body/qa/update/${form.id}`, form)
    },
    delete(accreditation_body_qa_id) {
        return httpClient.delete(`accreditation/body/qa/delete/${accreditation_body_qa_id}`)
    },
    get() {
        return httpClient.get(`accreditation/body/qa`)
    },
    getQualityAssuranceOrganizations() {
        return httpClient.get(`qa`)
    },
}

export default accreditationBodyQAService