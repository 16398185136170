import universityMOOCService from "@/services/university/universityMOOC.service"

const universityMOOC = {
    namespaced: true,
    actions: {

        async DELETE_UNIVERSITY_MOOC({commit}, id) {
            try {
                const data = await universityMOOCService.delete(id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_UNIVERSITY_MOOC({commit, state}, form) {
            try {
                await universityMOOCService.put(form)
                return {result: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return false
            }
        },

        async POST_UNIVERSITY_MOOC({commit, state}, form) {
            try {
                await universityMOOCService.post(form)
                return {result: true}
            } catch (e) {
                if (e.response.status === 409){
                    return {message: e.response.message, result: false}
                } else {
                    commit('SET_ERROR', e, {root: true})
                }
            }
            return false
        },

        async GET_UNIVERSITY_MOOC({commit}) {
            try {
                const {status, data} = await universityMOOCService.get()
                if (status === 200) {
                    console.log('GET_UNIVERSITY_MOOC', data)
                    commit('SET_UNIVERSITY_MOOC', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_UNIVERSITY_MOOC(state, data) {
            state.universityMOOC_form.universityMOOCs = data
        },
    },
    state: {
        universityMOOC_form: {
            universityMOOC: [],
        }
    },
    getters: {}
}

export default universityMOOC