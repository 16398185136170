//Сервис для дисциплин
import httpClient from "@/services/http.service"

const mobilityService = {
    post(form) {
        return httpClient.post(`mobility/create`, form)
    },
    put(form) {
        return httpClient.put(`mobility/update/${form.id}`, form)
    },
    delete(mobility_id) {
        return httpClient.delete(`mobility/delete/${mobility_id}`)
    },
    get() {
        return httpClient.get(`mobility`)
    },
    getMobilityTypes() {
        return httpClient.get(`mobility/type`)
    },
    getCountries() {
        return httpClient.get(`common/country`)
    },
    getUniversities() {
        return httpClient.get(`university`)
    },
    getForeignUniversities() {
        return httpClient.get(`foreign-university`)
    },
    getPaymentTypes() {
        return httpClient.get(`mobility/payment-type`)
    },
    getAgreementTypes() {
        return httpClient.get(`mobility/agreement-type`)
    },
    getMobilityStudyForms() {
        return httpClient.get(`mobility/study-form`)
    },
    getStudentByNameAndIid(search_field){
        return httpClient.get(`student-data/search?search_field=${search_field}`)
    },
    getForeignUniversitiesById(university_id){
        return httpClient.get(`foreign-university/show/${university_id}`)
    },
    getUniversitiesById(university_id){
        return httpClient.get(`university/show/${university_id}`)
    },
}

export default mobilityService