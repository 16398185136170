
import professionalStandardService from "@/services/education/professionalStandard.service"

const professionalStandard = {
    namespaced: true,
    actions: {

        async DELETE_PROFESSIONAL_STANDARD({commit}, professional_standard_id) {
            try {
                const data = await professionalStandardService.delete(professional_standard_id);
                if (data) {
                    console.log(data, 'professional_standard deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_PROFESSIONAL_STANDARD({commit, state}, form) {

            try {
                await professionalStandardService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_PROFESSIONAL_STANDARD({commit, state}, form) {

            try {
                await professionalStandardService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_PROFESSIONAL_STANDARDS({commit}) {
            try {
                const {status, data} = await professionalStandardService.get()
                if (status === 200) {
                    console.log('GET_PROFESSIONAL_STANDARDS', data)
                    commit('SET_PROFESSIONAL_STANDARDS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_PROFESSIONAL_STANDARDS(state, data) {
            state.professionalStandard_form.professionalStandards = data
        },
    },
    state: {
        professionalStandard_form: {

            professionalStandards: []
        }
    },
    getters: {}
}

export default professionalStandard