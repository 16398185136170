import httpClient from "@/services/http.service"

const publicationService = {
    get() {
        return httpClient.get('publication')
    },
    getScienceField() {
        return httpClient.get('science-field')
    },
    post(sendDataObject){
        return httpClient.post(`publication/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`publication/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(publication_id) {
        return httpClient.delete(`publication/delete/${publication_id}`)
    },
    getAll(university_id, size, page) {
        return httpClient.get(`publication/all?university_id=${university_id}&size=${size}&page=${page}`)
    },
}

export default publicationService