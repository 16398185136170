
import doubleDiplomaProgramService from "@/services/archive/doubleDiplomaProgram.service"

const doubleDiplomaProgram = {
    namespaced: true,
    actions: {

        async DELETE_DOUBLE_DIPLOMA_PROGRAM({commit}, double_diploma_program_id) {
            try {
                const data = await doubleDiplomaProgramService.delete(double_diploma_program_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_DOUBLE_DIPLOMA_PROGRAM({commit, state}, form) {

            try {
                await doubleDiplomaProgramService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_DOUBLE_DIPLOMA_PROGRAM({commit, state}, form) {

            try {
                await doubleDiplomaProgramService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DOUBLE_DIPLOMA_PROGRAMS({commit}) {
            try {
                const {status, data} = await doubleDiplomaProgramService.get()
                if (status === 200) {
                    console.log('GET_DOUBLE_DIPLOMA_PROGRAMS', data)
                    commit('SET_DOUBLE_DIPLOMA_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

    },
    mutations: {

        SET_DOUBLE_DIPLOMA_PROGRAMS(state, data) {
            state.doubleDiplomaProgram_form.doubleDiplomaPrograms = data
        },
    },
    state: {
        doubleDiplomaProgram_form: {

            doubleDiplomaPrograms: []
        }
    },
    getters: {}
}

export default doubleDiplomaProgram