
import digitalResourceService from "@/services/university/digitalResource.service"

const digitalResource = {
    namespaced: true,
    actions: {

        async DELETE_DIGITAL_RESOURCE({commit}, digital_resource_id) {
            try {
                const data = await digitalResourceService.delete(digital_resource_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_DIGITAL_RESOURCE({commit, state}, form) {

            try {
                await digitalResourceService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_DIGITAL_RESOURCE({commit, state}, form) {

            try {
                await digitalResourceService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DIGITAL_RESOURCES({commit}) {
            try {
                const {status, data} = await digitalResourceService.get()
                if (status === 200) {
                    console.log('GET_DIGITAL_RESOURCES', data)
                    commit('SET_DIGITAL_RESOURCES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_DIGITAL_RESOURCES(state, data) {
            state.digitalResource_form.digitalResources = data
        },
    },
    state: {
        digitalResource_form: {

            digitalResources: [],
        }
    },
    getters: {}
}

export default digitalResource