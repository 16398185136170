
import accreditationService from "@/services/university/accreditation.service"

const accreditation = {
    namespaced: true,
    actions: {

        async DELETE_ACCREDITATION({commit}, accreditation_id) {
            try {
                const data = await accreditationService.delete(accreditation_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_ACCREDITATION({commit, state}, form) {

            try {
                await accreditationService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ACCREDITATION({commit, state}, form) {

            try {
                await accreditationService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ACCREDITATIONS({commit}) {
            try {
                const {status, data} = await accreditationService.get()
                if (status === 200) {
                    console.log('GET_ACCREDITATIONS', data)
                    commit('SET_ACCREDITATIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_ACCREDITATION_TYPES({commit}) {
            try {
                const {status, data} = await accreditationService.getAccreditationTypes()
                if (status === 200) {
                    console.log('GET_ACCREDITATION_TYPES', data)
                    commit('SET_ACCREDITATION_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {

        SET_ACCREDITATIONS(state, data) {
            state.accreditation_form.accreditations = data
        },
        SET_ACCREDITATION_TYPES(state, data) {
            state.accreditation_form.accreditationTypes = data
        },
    },
    state: {
        accreditation_form: {
            accreditations: [],
            accreditationTypes: [],
        },
        data:[]
    },
    getters: {}
}

export default accreditation