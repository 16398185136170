import httpClient from "@/services/http.service"

const gosoTemplateService = {
    get() {
        return httpClient.get(`goso-template`)
    },
    post(sendDataObject){
        return httpClient.post(`goso-template/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`goso-template/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(gosoTemplate_id) {
        return httpClient.delete(`goso-template/delete/${gosoTemplate_id}`)
    },
}

export default gosoTemplateService