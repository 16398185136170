import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import authService from '@/services/auth.service'

const routes = [
    {
        path: '/',
        name: 'Default',
        redirect: '/home',
        component: () => import('../layouts/DefaultLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: HomeView,
            },
            {
                path: '/education-program',
                name: 'education-program',
                component: () => import('../views/education/EducationProgram.vue')
            },
            {
                path: '/education-program-cu',
                name: 'education-program-cu',
                component: () => import('../views/education/EducationProgramCU.vue')
            },
            {
                path: '/registry-administrator-education-program',
                name: 'registry-administrator-education-program',
                component: () => import('../views/education/registry-administrator/RegistryAdministratorEducationProgram.vue')
            },
            {
                path: '/head-education-program',
                name: 'head-education-program',
                component: () => import('../views/education/head/HeadEducationProgram.vue')
            },
            {
                path: '/representative-education-program',
                name: 'representative-education-program',
                component: () => import('../views/education/representative/RepresentativeEducationProgram.vue')
            },
            {
                path: '/expert-education-program',
                name: 'expert-education-program',
                component: () => import('../views/education/expert/ExpertEducationProgram.vue')
            },
            {
                path: '/education-discipline',
                name: 'education-discipline',
                component: () => import('../views/education/EducationDiscipline.vue')
            },
            {
                path: '/formation-education-program',
                name: 'formation-education-program',
                component: () => import('@/views/education/FormationEducationProgram.vue')
            },
            {
                path: '/professional-standard',
                name: 'professional-standard',
                component: () => import('@/views/education/ProfessionalStandard.vue')
            },
            {
                path: '/distance-learning',
                name: 'distance-learning',
                component: () => import('@/views/archive/DistanceLearning.vue')
            },
            {
                path: '/double-diploma-program',
                name: 'double-diploma-program',
                component: () => import('@/views/archive/DoubleDiplomaProgram.vue')
            },
            {
                path: '/foreign-student',
                name: 'foreign-student',
                component: () => import('@/views/archive/ForeignStudent.vue')
            },
            {
                path: '/archive-foreign-teacher',
                name: 'archive-foreign-teacher',
                component: () => import('@/views/archive/ForeignTeacher.vue')
            },
            {
                path: '/international-activity',
                name: 'international-activity',
                component: () => import('@/views/archive/InternationalActivity.vue')
            },
            {
                path: '/igpa',
                name: 'igpa',
                component: () => import('@/views/archive/IGPA.vue')
            },
            {
                path: '/quota-contingent',
                name: 'quota-contingent',
                component: () => import('@/views/archive/QuotaContingent.vue')
            },
            {
                path: '/silver-education-nonformal',
                name: 'silver-education-nonformal',
                component: () => import('@/views/archive/SilverEducationNonformal.vue')
            },
            {
                path: '/student-data',
                name: 'student-data',
                component: () => import('@/views/student/StudentData.vue')
            },
            {
                path: '/mobility',
                name: 'mobility',
                component: () => import('@/views/student/Mobility.vue')
            },
            {
                path: '/trilingual-education',
                name: 'trilingual-education',
                component: () => import('@/views/archive/TrilingualEducation.vue')
            },
            {
                path: '/academic-mobility',
                name: 'academic-mobility',
                component: () => import('@/views/archive/AcademicMobility.vue')
            },
            {
                path: '/foreign-university',
                name: 'foreign-university',
                component: () => import('@/views/university/ForeignUniversity.vue')
            },
            {
                path: '/university',
                name: 'university',
                component: () => import('@/views/university/University.vue')
            },
            {
                path: '/building',
                name: 'building',
                component: () => import('@/views/university/Building.vue')
            },
            {
                path: '/laboratory',
                name: 'laboratory',
                component: () => import('@/views/university/Laboratory.vue')
            },
            {
                path: '/sport-facility',
                name: 'sport-facility',
                component: () => import('@/views/university/SportFacility.vue')
            },
            {
                path: '/dormitory',
                name: 'dormitory',
                component: () => import('@/views/university/Dormitory.vue')
            },
            {
                path: '/department',
                name: 'department',
                component: () => import('@/views/university/Department.vue')
            },
            {
                path: '/special-need-condition',
                name: 'special-need-condition',
                component: () => import('@/views/university/SpecialNeedCondition.vue')
            },
            {
                path: '/university-representation',
                name: 'university-representation',
                component: () => import('@/views/university/UniversityRepresentation.vue')
            },
            {
                path: '/university-expense',
                name: 'university-expense',
                component: () => import('@/views/university/UniversityExpense.vue')
            },
            {
                path: '/accreditation-body',
                name: 'accreditation-body',
                component: () => import('@/views/university/AccreditationBody.vue')
            },
            {
                path: '/accreditation-body-qa',
                name: 'accreditation-body-qa',
                component: () => import('@/views/university/AccreditationBodyQA.vue')
            },
            {
                path: '/accreditation',
                name: 'accreditation',
                component: () => import('@/views/university/Accreditation.vue')
            },
            {
                path: '/additional-subunit',
                name: 'additional-subunit',
                component: () => import('@/views/university/AdditionalSubunit.vue')
            },
            {
                path: '/international-project',
                name: 'international-project',
                component: () => import('@/views/university/InternationalProject.vue')
            },
            {
                path: '/digital-resource',
                name: 'digital-resource',
                component: () => import('@/views/university/DigitalResource.vue')
            },
            {
                path: '/student-profile/:id',
                name: 'student-profile',
                component: () => import('@/views/student/StudentProfile.vue')
            },
            {
                path: '/research-works',
                name: 'research-works',
                component: () => import('@/views/university/ResearchWorks.vue')
            },
            {
                path: '/teacher-data',
                name: 'teacher-data-data',
                component: () => import('@/views/teacher/TeacherData.vue')
            },
            {
                path: '/foreign-teacher/:id',
                name: 'foreign-teacher',
                component: () => import('@/views/teacher/ForeignTeacher.vue')
            },
            {
                path: '/future-professions',
                name: 'future-professions',
                component: () => import('@/views/admin/FutureProfessions.vue')
            },
            {
                path: '/teacher-profile/:id',
                name: 'teacher-profile',
                component: () => import('@/views/teacher/TeacherProfile.vue')
            },
            {
                path: '/university-representation-profile/:id',
                name: 'university-representation-profile',
                component: () => import('@/views/university/UniversityRepresentationProfile.vue')
            },
            {
                path: '/journal',
                name: 'journal',
                component: () => import('@/views/university/Journal.vue')
            },
            {
                path: '/publication',
                name: 'publication',
                component: () => import('@/views/university/Publication.vue')
            },
            {
                path: '/teacher-publication',
                name: 'teacher-publication',
                component: () => import('@/views/teacher/TeacherPublication.vue')
            },
            {
                path: '/goso-template',
                name: 'goso-template',
                component: () => import('@/views/admin/GosoTemplate.vue')
            },
            {
                path: '/decree-goso-template',
                name: 'decree-goso-template',
                component: () => import('@/views/admin/DecreeGosoTemplate.vue')
            },
            {
                path: '/international-contracts',
                name: 'international-contracts',
                component: () => import('@/views/university/InternationalContracts.vue')
            },
            {
                path: '/teacher-mobility',
                name: 'teacher-mobility',
                component: () => import('@/views/teacher/TeacherMobility.vue')
            },
            {
                path: '/university-distance-learning',
                name: 'university-distance-learning',
                component: () => import('@/views/university/DistanceLearning.vue')
            },
            {
                path: '/university-online-learning',
                name: 'university-online-learning',
                component: () => import('@/views/university/OnlineLearning.vue')
            },
            {
                path: '/join-education-program',
                name: 'join-education-program',
                component: () => import('@/views/education/JoinEducationProgram.vue')
            },
            {
                path: '/university-support',
                name: 'university-support',
                component: () => import('@/views/university/UniversitySupport.vue')
            },
            {
                path: '/university-facilities',
                name: 'university-facilities',
                component: () => import('@/views/university/UniversityFacilities.vue')
            },
            {
                path: '/information-activities',
                name: 'information-activities',
                component: () => import('@/views/university/InformationActivities.vue')
            },
            {
                path: '/increasing-competence',
                name: 'increasing-competence',
                component: () => import('@/views/university/IncreasingCompetence.vue')
            },
            {
                path: '/social-media-posts',
                name: 'social-media-posts',
                component: () => import('@/views/university/SocialMediaPosts.vue')
            },
            {
                path: '/mooc',
                name: 'mooc',
                component: () => import('@/views/university/UniversityMOOC.vue')
            },
            {
                path: '/admin-publication',
                name: 'admin-publication',
                component: () => import('@/views/admin/AdminPublication.vue')
            },
            {
                path: '/admin-university-representation',
                name: 'admin-university-representation',
                component: () => import('@/views/admin/AdminUniversityRepresentation.vue')
            },
            {
                path: '/admin-international-contract',
                name: 'admin-international-contract',
                component: () => import('@/views/admin/AdminInternationalContract.vue')
            },
            {
                path: '/admin-international-project',
                name: 'admin-international-project',
                component: () => import('@/views/admin/AdminInternationalProject.vue')
            },
            {
                path: '/admin-student-data',
                name: 'admin-student-data',
                component: () => import('@/views/admin/AdminStudentData.vue')
            },
        ]
    },

    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/Login.vue'),
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const pagesWithoutAuthorization = ['login', 'register']
router.beforeEach(async (to) => {

    if (pagesWithoutAuthorization.includes(to.name)) {
        return true
    }

    if (!authService.isLoggedIn()) {
        let query = {}
        return {name: 'login', query: query}
    }


})


export default router
