import httpClient from "@/services/http.service"

const onlineLearningService = {
    get() {
        return httpClient.get(`online-learning`)
    },
    post(sendDataObject){
        return httpClient.post(`online-learning/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`online-learning/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(obj_id) {
        return httpClient.delete(`online-learning/delete/${obj_id}`)
    },
}

export default onlineLearningService