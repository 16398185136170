import httpClient from "@/services/http.service"

const foreignTeacherService = {
    post(form) {
        return httpClient.post(`foreign-teacher/create`, form)
    },
    put(form) {
        return httpClient.put(`foreign-teacher/update/${form.id}`, form)
    },
    delete(foreign_teacher_id) {
        return httpClient.delete(`foreign-teacher/delete/${foreign_teacher_id}`)
    },
    get() {
        return httpClient.get(`foreign-teacher`)
    },
}

export default foreignTeacherService