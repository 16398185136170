<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "UniversitySearch",
  computed: {
    ...mapState('educationProgram', ['educationProgram_form']),
  },
  data() {
    return {
      value: null
    }
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    is_foreign: {
      type: Number,
      required: false,
      default: 0,
    },
    modelValue: {
      required: true,
    },
  },
  methods:{
    ...mapActions('educationProgram', ['GET_SEARCH_EDUCATION_PROGRAM_UNIVERSITIES']),
    ...mapMutations('educationProgram', ['SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES', 'SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES']),

    async searchUniversities(name) {
      this.is_foreign
        ? this.SET_EDUCATION_PROGRAM_FOREIGN_UNIVERSITIES([])
        : this.SET_EDUCATION_PROGRAM_NATIONAL_UNIVERSITIES([])
      if (name.length > 2) {
        await this.GET_SEARCH_EDUCATION_PROGRAM_UNIVERSITIES({searching_university_name: name, is_foreign: this.is_foreign});
      }
    },
  }
}
</script>


<template>
  <Dropdown id="subunit_responsibility_id" v-model="value"
            :options="is_foreign ? educationProgram_form.foreignUniversities : educationProgram_form.nationalUniversities "
            optionLabel="name" optionValue="id" :placeholder="placeholder"
            @change="$emit('update:modelValue', $event.value)"
            @filter="searchUniversities($event.value)" :filter="true"/>
</template>

<style scoped>

</style>