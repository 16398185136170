import { createStore } from 'vuex'
import educationCommon from './common/educationCommon'
import educationProgram from './education/educationProgram'
import educationDiscipline from './education/educationDiscipline'
import formationEducationProgram from './education/formationEducationProgram'
import professionalStandard from './education/professionalStandard'

import distanceLearning from './archive/distanceLearning'
import doubleDiplomaProgram from './archive/doubleDiplomaProgram'
import foreignStudent from './archive/foreignStudent'
import foreignTeacher from './archive/foreignTeacher'
import internationalActivity from './archive/internationalActivity'
import igpa from './archive/igpa'
import quotaContingent from './archive/quotaContingent'
import silverEducationNonformal from './archive/silverEducationNonformal'
import trilingualEducation from './archive/trilingualEducation'
import academicMobility from './archive/academicMobility'

import foreignUniversity from "./university/foreignUniversity"
import university from "./university/university"
import building from "./university/building"
import laboratory from "./university/laboratory"
import sportFacility from "./university/sportFacility"
import dormitory from "./university/dormitory"
import department from "./university/department"
import specialNeedCondition from "./university/specialNeedCondition"
import universityRepresentation from "./university/universityRepresentation"
import universityExpense from "./university/universityExpense"
import accreditationBody from "./university/accreditationBody"
import accreditationBodyQA from "./university/accreditationBodyQA"
import accreditation from "./university/accreditation"
import digitalResource from "./university/digitalResource"
import additionalSubunit from "./university/additionalSubunit"
import internationalProject from "./university/internationalProject"


import studentCommon from "./common/studentCommon";

import common from "./common/common";
import studentData from "./student/studentData";
import teacherData from "./teacher/teacherData";
import teacherCommon from "./common/teacherCommon";
import mobility from "./student/mobility"
import researchWork from "./university/researchWork";
import futureProfessions from "@/store/admin/futureProfessions";
import journal from "@/store/university/journal";
import additionalForeignTeacher from "@/store/teacher/additionalForeignTeacher";
import publication from "@/store/university/publication";
import teacherPublication from "@/store/teacher/teacherPublication";
import internationalContracts from "@/store/university/internationalContracts";
import teacherMobility from "@/store/teacher/teacherMobility";
import universityDistanceLearning from "@/store/university/universityDistanceLearning";
import onlineLearning from "@/store/university/onlineLearning";
import joinEducationProgram from "@/store/education/joinEducationProgram";
import universitySupport from "@/store/university/universitySupport";
import universityFacilities from "@/store/university/universityFacilities";
import informationActivities from "@/store/university/InformationActivities";
import increasingCompetence from "@/store/university/increasingCompetence";
import gosoTemplate from "@/store/admin/gosoTemplate";
import socialMediaPosts from "@/store/university/socialMediaPosts";
import universityMOOC from "@/store/university/universityMOOC";

export default createStore({
  modules: {
    educationCommon,
    educationProgram,
    educationDiscipline,
    formationEducationProgram,
    professionalStandard,
    distanceLearning,
    doubleDiplomaProgram,
    foreignStudent,
    foreignTeacher,
    internationalActivity,
    igpa,
    quotaContingent,
    silverEducationNonformal,
    trilingualEducation,
    academicMobility,
    foreignUniversity,
    university,

    building,
    laboratory,
    sportFacility,
    dormitory,
    department,
    specialNeedCondition,
    universityRepresentation,
    universityExpense,
    accreditationBody,
    accreditationBodyQA,
    accreditation,
    digitalResource,
    additionalSubunit,
    internationalProject,

    common,
    studentCommon,
    studentData,
    teacherData,
    teacherCommon,
    mobility,
    researchWork,
    futureProfessions,
    journal,
    publication,
    teacherPublication,
    additionalForeignTeacher,
    internationalContracts,
    teacherMobility,
    universityDistanceLearning,
    onlineLearning,
    joinEducationProgram,
    universitySupport,
    universityFacilities,
    informationActivities,
    increasingCompetence,
    gosoTemplate,
    socialMediaPosts,
    universityMOOC,
  },
  state: {
    error: null
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = {
        message: error.message,
        errors: error?.response?.data?.error?.errors
      }
    }
  },
  actions: {
  },
  getters: {
    error: (s) => s.error
  },

})
