import httpClient from "@/services/http.service"

const studentDataService = {
    get(page = 1, last_name_doc = "", IID = "", enroll_status_ids = "", ep_name = "") {
        return httpClient.get(`student-data?page=` + page + `&` + `last_name_doc=` + last_name_doc + `&` + `IID=` + IID + `&`
            + `ep_name=` + ep_name + `&` + `enroll_status_ids=` + enroll_status_ids)
    },
    getStudentsAdmin(university_id, page = 1, last_name_doc = "", IID = "", enroll_status_ids = "", ep_name = "") {
        return httpClient.get(`student-data/admin?page=` + page + `&` + `university_id=` + university_id + `&` + `last_name_doc=` + last_name_doc + `&` + `IID=` + IID + `&`
            + `ep_name=` + ep_name + `&` + `enroll_status_ids=` + enroll_status_ids)
    },
    post(sendDataObject){
        return httpClient.post(`student-data/create`, sendDataObject)
    },
    getStudentById(student_id) {
        return httpClient.get(`student-data/get-student-by-id/${student_id}`)
    },
    put(sendDataObject){
        return httpClient.put(`student-data/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(student_id) {
        return httpClient.delete(`student-data/delete/${student_id}`)
    },
    deleteStudentSportCategory(id) {
        return httpClient.delete(`student/sports-category/delete/${id}`)
    },
    deleteStudentStudyLanguage(id) {
        return httpClient.delete(`student-data/language/delete/${id}`)
    },
}

export default studentDataService