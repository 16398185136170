
import accreditationBodyService from "@/services/university/accreditationBody.service"

const accreditationBody = {
    namespaced: true,
    actions: {

        async DELETE_ACCREDITATION_BODY({commit}, accreditation_body_id) {
            try {
                const data = await accreditationBodyService.delete(accreditation_body_id);
                if (data) {
                    console.log(data, 'deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_ACCREDITATION_BODY({commit, state}, form) {

            try {
                await accreditationBodyService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_ACCREDITATION_BODY({commit, state}, form) {

            try {
                await accreditationBodyService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ACCREDITATION_BODIES({commit}) {
            try {
                const {status, data} = await accreditationBodyService.get()
                if (status === 200) {
                    console.log('GET_ACCREDITATION_BODIES', data)
                    commit('SET_ACCREDITATION_BODIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ACCREDITATION_BODY_TYPES({commit}) {
            try {
                const {status, data} = await accreditationBodyService.getTypes()
                if (status === 200) {
                    console.log('GET_ACCREDITATION_BODY_TYPES', data)
                    commit('SET_ACCREDITATION_BODY_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


    },
    mutations: {
        SET_ACCREDITATION_BODIES(state, data) {
            state.accreditationBody_form.accreditationBodies = data
        },
        SET_ACCREDITATION_BODY_TYPES(state, data) {
            state.accreditationBody_form.accreditationBodyTypes = data
        },
    },
    state: {
        accreditationBody_form: {
            accreditationBodies: [],
            accreditationBodyTypes: []
        }
    },
    getters: {}
}

export default accreditationBody