import httpClient from "@/services/http.service"

const increasingCompetenceService = {
    get() {
        return httpClient.get(`increasing-competence`)
    },
    post(sendDataObject){
        return httpClient.post(`increasing-competence/create`, sendDataObject)
    },
    put(sendDataObject){
        return httpClient.put(`increasing-competence/update/${sendDataObject.id}`, sendDataObject)
    },
    delete(increasingCompetence_id) {
        return httpClient.delete(`increasing-competence/delete/${increasingCompetence_id}`)
    },
}

export default increasingCompetenceService