//Хранилище данных справочников
import studentCommonService from "@/services/common/studentCommon.service";

const studentCommon = {
    namespaced: true,
    actions: {
        async GET_ALL_STUDENT_COMMON({commit}) {
            try {
                const {status, data} = await studentCommonService.getAll()
                if (status === 200) {
                    commit('SET_ALL_STUDENT_COMMON', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_STUDENT_LOCATION_TYPES({commit}) {
            try {
                const {status, data} = await studentCommonService.getStudentLocationTypes()
                if (status === 200) {
                    commit('SET_STUDENT_LOCATION_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_ENROLL_STATUSES({commit}) {
            try {
                const {status, data} = await studentCommonService.getEnrollStatuses()
                if (status === 200) {
                    commit('SET_ENROLL_STATUSES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_PAYMENT_TYPES({commit}) {
            try {
                const {status, data} = await studentCommonService.getPaymentTypes()
                if (status === 200) {
                    commit('SET_PAYMENT_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_STUDY_FORMS({commit}) {
            try {
                const {status, data} = await studentCommonService.getStudyForms()
                if (status === 200) {
                    commit('SET_STUDY_FORMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_AFTER_GRADUATION_STATUSES({commit}) {
            try {
                const {status, data} = await studentCommonService.getAfterGraduationStatuses()
                if (status === 200) {
                    commit('SET_AFTER_GRADUATION_STATUSES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_SPORTS_CATEGORIES({commit}) {
            try {
                const {status, data} = await studentCommonService.getSportsCategories()
                if (status === 200) {
                    commit('SET_SPORTS_CATEGORIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_SPORTS({commit}) {
            try {
                const {status, data} = await studentCommonService.getSports()
                if (status === 200) {
                    commit('SET_SPORTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_DISABILITY_CATEGORIES({commit}) {
            try {
                const {status, data} = await studentCommonService.getDisabilityCategories()
                if (status === 200) {
                    commit('SET_DISABILITY_CATEGORIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_EMPLOYMENT_STATUSES({commit}) {
            try {
                const {status, data} = await studentCommonService.getEmploymentStatuses()
                if (status === 200) {
                    commit('SET_EMPLOYMENT_STATUSES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_SPECIAL_NEED_TYPES({commit}) {
            try {
                const {status, data} = await studentCommonService.getSpecialNeedTypes()
                if (status === 200) {
                    commit('SET_SPECIAL_NEED_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
    },
    mutations: {
        SET_ALL_STUDENT_COMMON(state, data) {
            state.studentCommon_form.all = data
        },
        SET_STUDENT_LOCATION_TYPES(state, data) {
            state.studentCommon_form.studentLocationTypes = data
        },
        SET_ENROLL_STATUSES(state, data) {
            state.studentCommon_form.enrollStatuses = data
        },
        SET_PAYMENT_TYPES(state, data) {
            state.studentCommon_form.paymentTypes = data
        },
        SET_STUDY_FORMS(state, data) {
            state.studentCommon_form.studyForms = data
        },
        SET_AFTER_GRADUATION_STATUSES(state, data) {
            state.studentCommon_form.afterGraduationStatuses = data
        },
        SET_SPORTS_CATEGORIES(state, data) {
            state.studentCommon_form.sportsCategories = data
        },
        SET_SPORTS(state, data) {
            state.studentCommon_form.sports = data
        },
        SET_DISABILITY_CATEGORIES(state, data) {
            state.studentCommon_form.disabilityCategories = data
        },
        SET_EMPLOYMENT_STATUSES(state, data) {
            state.studentCommon_form.employmentStatuses = data
        },
        SET_SPECIAL_NEED_TYPES(state, data) {
            state.studentCommon_form.specialNeedTypes = data
        },
    },
    state: {
        studentCommon_form: {
            all: {},
            studentLocationTypes: [],
            enrollStatuses: [],
            paymentTypes: [],
            studyForms: [],
            afterGraduationStatuses: [],
            sportsCategories: [],
            sports: [],
            disabilityCategories: [],
            employmentStatuses: [],
            specialNeedTypes: [],
        }

    },
    getters: {}
}

export default studentCommon