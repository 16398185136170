//Общее хранилище данных
import educationCommonService from "@/services/common/educationCommon.service"

const educationCommon = {
    namespaced: true,
    actions: {

        async GET_DEPARTMENTS({commit}) {
            try {
                const {status, data} = await educationCommonService.getDepartments()
                if (status === 200) {
                    console.log('GET_DEPARTMENTS', data)
                    commit('SET_DEPARTMENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_STUDY_LEVELS({commit}) {
            try {
                const {status, data} = await educationCommonService.getStudyLevels()
                if (status === 200) {
                    console.log('GET_STUDY_LEVELS', data)
                    commit('SET_STUDY_LEVELS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_NRK_LEVELS({commit}) {
            try {
                const {status, data} = await educationCommonService.getNrkLevels()
                if (status === 200) {
                    console.log('GET_NRK_LEVELS', data.data)
                    commit('SET_NRK_LEVELS', data.data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_ORK_LEVELS({commit}) {
            try {
                const {status, data} = await educationCommonService.getOrkLevels()
                if (status === 200) {
                    console.log('GET_ORK_LEVELS', data.data)
                    commit('SET_ORK_LEVELS', data.data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_FIELDS({commit}, study_level_id) {
            try {
                const {status, data} = await educationCommonService.getEducationFields(study_level_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_FIELDS', data)
                    commit('SET_EDUCATION_FIELDS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_GROUPS({commit}, education_direction_id) {
            try {
                const {status, data} = await educationCommonService.getEducationGroups(education_direction_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_GROUPS', data)
                    commit('SET_EDUCATION_GROUPS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_DIRECTIONS({commit}, education_field_id) {
            try {
                const {status, data} = await educationCommonService.getEducationDirections(education_field_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_DIRECTIONS', data)
                    commit('SET_EDUCATION_DIRECTIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_DISCIPLINE_COMMON({commit}, university_id) {
            try {
                const {status, data} = await educationCommonService.getEducationDisciplineCommon(university_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_DISCIPLINE_COMMON', data)
                    commit('SET_EDUCATION_DISCIPLINE_COMMON', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_PROGRAM_COMMON({commit}, university_id) {
            try {
                const {status, data} = await educationCommonService.getEducationProgramCommon(university_id)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_COMMON', data)
                    commit('SET_EDUCATION_PROGRAM_COMMON', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_DECREE_GOSO_TEMPLATES({commit}) {
            try {
                const {status, data} = await educationCommonService.getDecreeGosoTemplates()
                if (status === 200) {
                    console.log('GET_DECREE_GOSO_TEMPLATES', data)
                    commit('SET_DECREE_GOSO_TEMPLATES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        }
    },
    mutations: {
        SET_DEPARTMENTS(state, data) {
            state.educationCommon_form.departments = data
        },
        SET_STUDY_LEVELS(state, data) {
            state.educationCommon_form.studyLevels = data
        },
        SET_NRK_LEVELS(state, data) {
            state.educationCommon_form.nrkLevels = data
        },
        SET_ORK_LEVELS(state, data) {
            state.educationCommon_form.orkLevels = data
        },
        SET_EDUCATION_FIELDS(state, data) {
            state.educationCommon_form.educationFields = data
        },
        SET_EDUCATION_GROUPS(state, data) {
            state.educationCommon_form.educationGroups = data
        },
        SET_EDUCATION_DIRECTIONS(state, data) {
            state.educationCommon_form.educationDirections = data
        },
        SET_EDUCATION_DISCIPLINE_COMMON(state, data) {
            state.educationCommon_form.educationDisciplineCommon = data
        },
        SET_EDUCATION_PROGRAM_COMMON(state, data) {
            state.educationCommon_form.educationProgramCommon = data
        },
        SET_DECREE_GOSO_TEMPLATES(state, data) {
            state.educationCommon_form.decreeGosoTemplates = data
        },
    },
    state: {
        educationCommon_form: {

            departments: [],
            studyLevels: [],
            nrkLevels: [],


            educationSemesterTypes: [
                {
                    "id": 1,
                    "name": "семестр"
                },
                {
                    "id": 2,
                    "name": "триместр"
                }
            ],
            // studyLanguages: [
            //     { id: 39, name: 'English' },
            //     { id: 82, name: 'Қазақ тілі' },
            //     { id: 137, name: 'Русский язык' }
            // ],
            studyForms: [{id: 1, name: 'Очное'}, {id: 2, name: 'Заочное'}],
            degreeTypes: [
                {
                    "id": 1,
                    "name_kz": "гуманитарлық білім бакалавры",
                    "name_ru": "бакалавра гуманитарных знаний ",
                    "name_eng": "Bachelor of Humanities"
                },
                {
                    "id": 2,
                    "name_kz": "әлеуметтік білім бакалавры",
                    "name_ru": "бакалавра социальных знаний ",
                    "name_eng": "Bachelor of Social Knowledge"
                },
                {
                    "id": 4,
                    "name_kz": "экономика және бизнес бакалавры",
                    "name_ru": "бакалавра экономики и бизнеса",
                    "name_eng": "Bachelor of Economics and Business"
                },
                {
                    "id": 11,
                    "name_kz": "экономика және бизнес бакалавры",
                    "name_ru": "бакалавра экономики и бизнеса ",
                    "name_eng": "Bachelor of Economics and Business"
                },
                {
                    "id": 12,
                    "name_kz": "қызмет көрсету саласының бакалавры",
                    "name_ru": "бакалавра в области услуг ",
                    "name_eng": "Bachelor of Services"
                },
                {
                    "id": 13,
                    "name_kz": "экономика және бизнес бакалавры",
                    "name_ru": "бакалавра экономики и бизнеса ",
                    "name_eng": "Bachelor of Economics and Business"
                },
                {
                    "id": 15,
                    "name_kz": "құқық бакалавры",
                    "name_ru": "бакалавра права ",
                    "name_eng": "Bachelor of Law"
                },
                {
                    "id": 17,
                    "name_kz": "техника және технологиялар бакалавры",
                    "name_ru": "бакалавра техники и технологий ",
                    "name_eng": "Bachelor of Engineering and Technology"
                },
                {
                    "id": 19,
                    "name_kz": "қызмет көрсету саласының бакалавры",
                    "name_ru": "бакалавра в области услуг ",
                    "name_eng": "Bachelor of Services"
                },
                {
                    "id": 22,
                    "name_kz": "педагогика ғылымдарының магистрі",
                    "name_ru": "магистра педагогических наук ",
                    "name_eng": "Master of Pedagogical Sciences"
                },
                {
                    "id": 24,
                    "name_kz": "әлеуметтік ғылымдар магистрі",
                    "name_ru": "магистра социальных наук ",
                    "name_eng": "Master of Social Sciences"
                },
                {
                    "id": 25,
                    "name_kz": "экономика ғылымдарының магистрі",
                    "name_ru": "магистра экономических наук ",
                    "name_eng": "Master of Economic Sciences"
                },
                {
                    "id": 30,
                    "name_kz": "құқық ғылымдарының магистрі",
                    "name_ru": "магистра юридических наук ",
                    "name_eng": "Master of Law Sciences"
                },
                {
                    "id": 31,
                    "name_kz": "қызмет көрсету саласының ғылым магистрі",
                    "name_ru": "магистра наук в области услуг ",
                    "name_eng": "Master of Science in Services"
                },
                {
                    "id": 32,
                    "name_kz": "әлеуметтік білім магистрі",
                    "name_ru": "магистра социальных знаний ",
                    "name_eng": "Master of Social Knowledge"
                },
                {
                    "id": 33,
                    "name_kz": "бизнес және басқару магистрі",
                    "name_ru": "магистра бизнеса и управления ",
                    "name_eng": "Master of business and management  "
                },
                {
                    "id": 37,
                    "name_kz": "құқық магистрі",
                    "name_ru": "магистра права ",
                    "name_eng": "Master of Law"
                },
                {
                    "id": 38,
                    "name_kz": "бизнес және басқару магистрі",
                    "name_ru": "магистра бизнеса и управления ",
                    "name_eng": "Master of business and management  "
                },
                {
                    "id": 48,
                    "name_kz": "Master of Business Administration",
                    "name_ru": "Master of Business Administration",
                    "name_eng": "Master of Business Administration"
                },
                {
                    "id": 49,
                    "name_kz": "медицина ғылымдарының магистрі",
                    "name_ru": "магистра медицинских наук",
                    "name_eng": "Master of Medical Sciences"
                },
                {
                    "id": 50,
                    "name_kz": "денсаулық сақтау бакалавры",
                    "name_ru": "бакалавра здравоохранения",
                    "name_eng": "Bachelor in Healthcare"
                },
                {
                    "id": 51,
                    "name_kz": "философия докторы",
                    "name_ru": "доктор философии",
                    "name_eng": "Doctor of philosophy"
                },
                {
                    "id": 52,
                    "name_kz": "отбасылық дәрігер",
                    "name_ru": "врач семейной медицины",
                    "name_eng": "general practitioner"
                },
                {
                    "id": 53,
                    "name_kz": "бизнес және менеджмент бакалавры",
                    "name_ru": "бакалавра бизнеса и управления",
                    "name_eng": "Bachelor of Business and Management"
                },
                {
                    "id": 54,
                    "name_kz": "Іскерлік әкімшіліктің докторы",
                    "name_ru": "доктор делового администрирования",
                    "name_eng": "Doctor of Business Administration"
                },
                {
                    "id": 55,
                    "name_kz": "Ақпараттық-коммуникациялық технологиялар саласындағы бакалавры",
                    "name_ru": "бакалавра в области информационно - коммуникационных технологий",
                    "name_eng": "Bachelor of information and communication technologies "
                },
                {
                    "id": 56,
                    "name_kz": "техника ғылымдарының магистрі",
                    "name_ru": "магистр технических наук",
                    "name_eng": "Master of technical sciences"
                },
                {
                    "id": 57,
                    "name_kz": "Ақпараттық-коммуникациялық технологиялар саласындағы бакалавры",
                    "name_ru": "бакалавра в области информационно - коммуникационных технологий ",
                    "name_eng": "Bachelor in Information and Communication Technologies"
                },
                {
                    "id": 58,
                    "name_kz": "Ақпараттық-коммуникациялық технологиялар саласындағы бакалавры",
                    "name_ru": "бакалавра в области информационно - коммуникационных технологий ",
                    "name_eng": "Bachelor in Information and Communication Technologies "
                }
            ],

            educationDisciplineCommon: {},
            educationProgramCommon: {},
            decreeGosoTemplates: [],
            educationFields: [],
            educationGroups: [],
            educationDirections: [],

        }
    },
    getters: {}
}

export default educationCommon