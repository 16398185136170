import httpClient from "@/services/http.service"

const universityFacilitiesService = {
    post(form) {
        return httpClient.post(`university-facility/create`, form)
    },
    put(form) {
        return httpClient.put(`university-facility/update/${form.id}`, form)
    },
    delete(id) {
        return httpClient.delete(`university-facility/delete/${id}`)
    },
    get() {
        return httpClient.get(`university-facility`)
    },
}

export default universityFacilitiesService