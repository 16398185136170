//Сервис для дисциплин
import httpClient from "@/services/http.service"

const professionalStandardService = {
    post(form) {
        return httpClient.post(`professional-standard/create`, form)
    },
    put(form) {
        return httpClient.put(`professional-standard/update/${form.id}`, form)
    },
    delete(professional_standard_id) {
        return httpClient.delete(`professional-standard/delete/${professional_standard_id}`)
    },
    get() {
        return httpClient.get(`professional-standard`)
    },
}

export default professionalStandardService